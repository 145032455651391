import React from "react"
import { Segment } from "semantic-ui-react"
import styles from "./Privacy.module.css";

const Privacy = () => (

    <div className={styles.container}>
        <div className={styles.content}>
            <Segment.Group className={styles.segmentGroup}>
                <Segment className={styles.header}>
                    <h2>Amsternannies - Privacy statement</h2>
                </Segment>
                <Segment className={styles.content}>
                    <p>
                    Amsternannies is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.
                    </p>

                    <h5>Contactgegevens</h5>
                    <p>
                        https://amsternannies.nl/<br/>
                        Zuiderkerk<br/>
                        Zuiderkerkhof 72<br/>
                        1011 WB, Amsterdam<br/>
                        020-3080003<br/>
                    </p>

                    <h5>
                    Persoonsgegevens die wij verwerken
                    </h5>
                    <p>
                        Amsternannies verwerkt je persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je deze gegevens zelf aan ons verstrekt.
                        Hieronder vind je een overzicht van de persoonsgegevens die wij verwerken:
                        <ul>
                            <li>Voor- en achternaam</li>
                            <li>E-mailadres</li>
                        </ul>
                    </p>
                        

                    <h5>
                    Bijzondere en/of gevoelige persoonsgegevens die wij verwerken
                    </h5>
                    <p>
                        Onze website en/of dienst verzamelt alleen gegevens over bezoekers die als Nanny aangesloten zijn bij Amsternannies.
                        Zodoende verzamelen wij geen gegevens over personen jonger dan 16 jaar.
                    </p>

                    <h5>
                    Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken
                    </h5>
                    <p>
                        Amsternannies verwerkt jouw persoonsgegevens voor de volgende doelen:
                        <ul><li>Het aanmaken van een account</li></ul>
                    </p>

                    <h5>
                    Geautomatiseerde besluitvorming
                    </h5>
                    <p>
                    Amsternannies neemt geen besluiten over zaken die (aanzienlijke) gevolgen kunnen hebben voor personen op basis van geautomatiseerde verwerkingen.
                    </p>

                    <h5>
                    Hoe lang we persoonsgegevens bewaren
                    </h5>
                    <p>
                    Amsternannies bewaart je persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor je gegevens worden verzameld.
                    </p>

                    <h5>
                    Delen van persoonsgegevens met derden
                    </h5>
                    <p>
                    Amsternannies verstrekt uitsluitend aan derden en alleen als dit nodig is voor de uitvoering van onze overeenkomst met jou of om te voldoen aan een wettelijke verplichting.
                    </p>

                    <h5>
                    Cookies, of vergelijkbare technieken, die wij gebruiken
                    </h5>
                    <p>
                    Amsternannies gebruikt alleen technische en functionele cookies. En analytische cookies die geen inbreuk maken op je privacy. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen op jouw computer, tablet of smartphone. De cookies die wij gebruiken zijn noodzakelijk voor de technische werking van de website en jouw gebruiksgemak. Ze zorgen ervoor dat de website naar behoren werkt en onthouden bijvoorbeeld jouw voorkeursinstellingen. Ook kunnen wij hiermee onze website optimaliseren. Je kunt je afmelden voor cookies door je internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kun je ook alle informatie die eerder is opgeslagen via de instellingen van je browser verwijderen.
                    </p>

                    <h5>
                    Gegevens inzien, aanpassen of verwijderen
                    </h5>
                    <p>
                    Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heb je het recht om je eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door Amsternannies en heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan indienen om de persoonsgegevens die wij van jou beschikken in een computerbestand naar jou of een ander, door jou genoemde organisatie, te sturen. <br/>
                    Je kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van je persoonsgegevens of verzoek tot intrekking van je toestemming of bezwaar op de verwerking van jouw persoonsgegevens sturen naar info@amsternannies.nl <br/>
                    Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, vragen wij jou een kopie van je identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie je pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van je privacy. We reageren zo snel mogelijk, maar binnen vier weken, op jouw verzoek. <br/>
                    Amsternannies wil je er tevens op wijzen dat je de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons<br/>
                    </p>

                    <h5>
                        Hoe wij persoonsgegevens beveiligen
                    </h5>
                    <p>
                        Amsternannies neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via info@amsternannies.nl
                    </p>

                    <h5>
                    Wijzigen privacy statement
                    </h5>
                    <p>
                    Dit privacy statement kan worden gewijzigd, bijvoorbeeld als er nieuwe functionaliteiten worden toegevoegd. Wijzigingen zullen worden bekend gemaakt door middel van een bericht aan de gebruikers.
                    </p>
                </Segment>
            </Segment.Group>
        </div>
    </div>
)

export default Privacy;