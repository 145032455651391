import React from "react";
import styles from "./index.module.css";
import { DienstenTable } from "../DienstenTable";
import { useDienstenListener } from "../../hooks/useDiensten";
import { useReactionsListener, useReactionsPerDienstId } from "../../hooks/useReactions";
import { Button, Dimmer, Form, Icon, Loader } from "semantic-ui-react";
import { useModal } from "../../hooks/useModal";
import { AddEditModal } from "./AddEditModal";
import { useFamilies, useFamiliesById } from "../../hooks/useFamilies";
import { useKnownNannies } from "../../hooks/useKnownNannies";
import MonthDropdown from "../MonthDropdown";
import moment from "moment";
import { CancelDienstButton } from "../CancelDienstButton";
import { DeleteModal } from "./DeleteModal";

export const DienstenTab = () => {
	const [allFutureDiensten, setAllFutureDiensten] = React.useState(true);
	const [month, setMonth] = React.useState(moment().date(1).format("YYYY_MM"));

	const { diensten, loading: loadingDiensten } = useDienstenListener(
		null,
		allFutureDiensten ? null : month,
		allFutureDiensten ? null : month
	);
	const { reactions, loading: loadingReactions } = useReactionsListener();

	const familiesById = useFamiliesById();
	const knownNanniesPerFamily = useKnownNannies();

	const { modalProps: addEditModalProps, openModal: openAddEditModal } = useModal(diensten);
	const { modalProps: deleteModalProps, openModal: openDeleteModal } = useModal(diensten);

	const loading = loadingDiensten || loadingReactions || !familiesById || !knownNanniesPerFamily;

	return (
		<div className={styles.container}>
			{loading && (
				<Dimmer active>
					<Loader />
				</Dimmer>
			)}
			<AddEditModal
				{...addEditModalProps}
				knownNanniesPerFamily={knownNanniesPerFamily}
				familiesById={familiesById}
				reactions={reactions}
			/>
			<DeleteModal
				{...deleteModalProps}
				knownNanniesPerFamily={knownNanniesPerFamily}
				familiesById={familiesById}
				reactions={reactions}
			/>
			<div className={styles.headerContainer}>
				<div className={styles.selectMonthContainer}>
					<Form>
						<Form.Group inline style={{ marginBottom: 0 }}>
							<Form.Radio
								label="Toon alle toekomstige diensten"
								value={true}
								checked={allFutureDiensten}
								onChange={(e, { value }) => setAllFutureDiensten(value)}
							/>
							<Form.Radio
								label="Toon diensten voor specifieke maand"
								value={false}
								checked={!allFutureDiensten}
								onChange={(e, { value }) => setAllFutureDiensten(value)}
							/>
							<MonthDropdown
								fluid={false}
								month={month}
								setMonth={setMonth}
								monthsAhead={12}
								disabled={allFutureDiensten}
							/>
						</Form.Group>
					</Form>
				</div>
				<div style={{ display: "flex" }}>
					<CancelDienstButton />
					<Button
						icon
						loading={loading}
						labelPosition="right"
						onClick={() => openAddEditModal(null)}
					>
						Nieuwe dienst
						<Icon name="add" />
					</Button>
				</div>
			</div>
			<DienstenTable
				diensten={diensten}
				reactions={reactions}
				openAddEditModal={openAddEditModal}
				openDeleteModal={openDeleteModal}
			/>
		</div>
	);
};
