import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTariffs } from "../redux/modules/Tariffs";

export const useTariffs = () => {
    const dispatch = useDispatch();

    const tariffs = useSelector((state) => state.tariffs);

    React.useEffect(() => {
        if (tariffs.tariffs) return;
        if (tariffs.loading) return;
        
        dispatch(getTariffs());
    }, [dispatch]);

    return tariffs.tariffs;
}