import { createSlice } from "@reduxjs/toolkit"

import firebase from 'firebase/app';
import 'firebase/firestore';

const familiesSlice = createSlice({
    name: 'families',
    initialState: {
        loading: false,
        families: null
    },
    reducers: {
        getFamiliesLoading: (state) => {
            state.loading = true;
        },
        getFamiliesSuccess: (state, action) => {
            const { data } = action.payload;
            state.families = data;
            state.loading = false;
        }
    }
})

export default familiesSlice.reducer

const { getFamiliesLoading, getFamiliesSuccess } = familiesSlice.actions

export const getFamilies = () => async dispatch => {
    dispatch(getFamiliesLoading());

    console.log("Getting families...");
    
    firebase.firestore().collection("families").onSnapshot((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
            let famobj = {
                id: doc.id,
                ...doc.data()
            }
            
            data.push(famobj);
        });

        data = data.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });

        dispatch(getFamiliesSuccess({data}));
    });
}

