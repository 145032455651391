import React from 'react';
import { connect } from 'react-redux';

import {
    Route,
    Redirect
} from 'react-router-dom';

const OnlyLoggedOutRoute = ({ component: Component, authUser, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            authUser ? (
                <Redirect
                    to={{
                        pathname: "/home",
                        state: { from: props.location }
                    }}
                />
            ) : (
                    <Component {...props} />
                )
        }
    />
);

const mapStateToProps = (state) => ({
    authUser: state.auth.authUser
})

export default connect(
    mapStateToProps,
    {}
)(OnlyLoggedOutRoute);