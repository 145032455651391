import React, { useEffect, useState } from "react";
import styles from "./FormGroup.module.css";

import { Accordion, Checkbox, Form, Icon, Label, Segment } from "semantic-ui-react";

const FormGroupHeader = ({ label, collapsable, basic, onToggleCheck, checked, disabled, info }) => {
	if (!label) return null;

	const handleOnChange = (evt) => {
		evt.stopPropagation();
		onToggleCheck(!checked);
	};

	const checkbox = onToggleCheck && (
		<Checkbox
			style={{ transform: "translateX(-15%) scale(0.7)", width: "3rem" }}
			toggle
			checked={checked}
			disabled={disabled}
			onChange={handleOnChange}
		/>
	);

	const collapseIcon = collapsable && <Icon name="dropdown" />;

	const content = (
		<span className={styles.header} disabled={disabled} basic={basic}>
			{collapseIcon}
			{checkbox}
			{label}
		</span>
	);

	return basic ? (
		content
	) : (
		<Label className={styles.label} disabled={disabled || (onToggleCheck && !checked)}>
			{content}
		</Label>
	);
};

const CollapsableFormGroup = ({
	label,
	children,
	buttongroup,
	defaultCollapsed,
	basic,
	onToggleCheck,
	checked,
	disabled,
	info,
	...rest
}) => {
	const [active, setActive] = useState(!defaultCollapsed);

	useEffect(() => {
		if (onToggleCheck) setActive(checked);
	}, [checked, onToggleCheck]);

	let canClickToCollapse = true;
	if (onToggleCheck && !checked) canClickToCollapse = false;
	if (disabled) canClickToCollapse = false;

	return (
		<Accordion style={{ padding: 0 }}>
			<Accordion.Title
				active={active}
				onClick={() => (canClickToCollapse ? setActive(!active) : null)}
				style={{ padding: 0 }}
			>
				<FormGroupHeader
					label={label}
					collapsable
					basic={basic}
					checked={checked}
					onToggleCheck={onToggleCheck}
					disabled={disabled}
					info={info}
				/>
			</Accordion.Title>
			<Accordion.Content active={active} style={{ padding: 0 }}>
				{active && (
					<div className={styles.content} {...rest}>
						{children}
					</div>
				)}
			</Accordion.Content>
		</Accordion>
	);
};

const FormGroup = ({
	label,
	children,
	buttongroup,
	basic,
	collapsable,
	defaultCollapsed,
	onToggleCheck,
	checked,
	disabled,
	info,
	contentStyle,
	...rest
}) => {
	if (!label) collapsable = false;

	return (
		<Segment basic={basic} {...rest} className={styles.segment}>
			{collapsable ? (
				<CollapsableFormGroup
					label={label}
					buttongroup={buttongroup}
					basic={basic}
					defaultCollapsed={defaultCollapsed}
					onToggleCheck={onToggleCheck}
					checked={checked}
					disabled={disabled}
					info={info}
					{...rest}
				>
					{children}
				</CollapsableFormGroup>
			) : (
				<>
					<FormGroupHeader label={label} disabled={disabled} info={info} />
					<div className={styles.content} style={contentStyle} {...rest}>
						{children}
					</div>
				</>
			)}
		</Segment>
	);
};

export { FormGroup };
