import React from 'react'
import { Button, Modal, Form } from 'semantic-ui-react'
import { amountTypes, enableAmountTypeForTariff, getAmountTypeLabel, getTariffOptions, tariffTypes } from '../../constants'

const TariffModal = ({ open, setOpen, onConfirm, tariffCode, tariff }) => {
    const [tariffType, setTariffType] = React.useState(tariffTypes.Old);
    const [tariffLabel, setTariffLabel] = React.useState("");
    const [amounts, setAmounts] = React.useState(Array(amountTypes.Last).fill(0));

    React.useEffect(() => {
        if (open && tariff) {
            setTariffType(tariff.type);
            setTariffLabel(tariff.label);
            setAmounts(tariff.amounts);
        } else {
            setTariffType(tariffTypes.Old);
            setTariffLabel("");
            setAmounts(Array(amountTypes.Last).fill(0));
        }
    }, [tariff, open]);

    let amountscontent = [];
    for (let a = 0; a < amountTypes.Last; a++) {
        if (!enableAmountTypeForTariff(a, tariffType)) continue;
        amountscontent.push(
            <Form.Input
                key={a}
                label={"Bedrag voor: " + getAmountTypeLabel(a)}
                value={amounts[a]}
                onChange={(e, { value }) => {
                    let newVal = value.replace(",", ".");
                    setAmounts((oldAmounts) => oldAmounts.map((oldAmount, idx) => idx === a ? newVal : oldAmount))
                }}
            />
        )
    }

    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm({
                type: tariffType,
                label: tariffLabel,
                amounts: amounts.map(parseFloat)
            });
        }
        setOpen(false);
    }

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size="small"
        >
            <Modal.Header>{tariff ? "Tarief aanpassen" : "Nieuw tarief toevoegen"}</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Input
                        label="Tariefcode"
                        type="text"
                        value={tariffCode}
                        disabled
                    />
                    <Form.Input
                        label="Naam"
                        type="text"
                        value={tariffLabel}
                        onChange={(e, { value }) => setTariffLabel(value)}
                    />
                    <Form.Dropdown
                        label="Tarief type"
                        selection
                        value={tariffType}
                        options={getTariffOptions()}
                        onChange={(e, { value }) => setTariffType(value)}
                    />
                    
                    {amountscontent}
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    content="Ok"
                    labelPosition='right'
                    icon='checkmark'
                    onClick={handleConfirm}
                    positive
                />
            </Modal.Actions>
        </Modal>
    )
}

export default TariffModal