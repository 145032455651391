import { createSlice } from "@reduxjs/toolkit"

import firebase from 'firebase/app';
import 'firebase/firestore';

const creditsSlice = createSlice({
    name: 'credits',
    initialState: {
        loading: false,
        credits: null
    },
    reducers: {
        getCreditsLoading: (state) => {
            state.loading = true;
        },
        getCreditsSuccess: (state, action) => {
            const { data } = action.payload;
            state.credits = data;
            state.loading = false;
        }
    }
})

export default creditsSlice.reducer

const { getCreditsLoading, getCreditsSuccess } = creditsSlice.actions

export const getCredits = () => async dispatch => {
    dispatch(getCreditsLoading());

    console.log("Getting credits...");
    
    firebase.firestore().collection("credits").onSnapshot((querySnapshot) => {
        let data = {};
        querySnapshot.forEach((doc) => {
            const docdata = doc.data();
            if (docdata.credits) {
                data[doc.id] = docdata.credits;
            }
        });

        dispatch(getCreditsSuccess({data}));
    });
}

