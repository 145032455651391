import { Button, Icon, Label, Modal, Table } from "semantic-ui-react";
import React, { useState } from "react";
import {
	DienstNannyStatus,
	getDienstNannyStatusLabel,
	getNannyStatusColor,
	getNannyStatusFontColor
} from "../../../constants";
import { useNanniesById } from "../../../hooks/useNannies";

const ReactionsModal = ({ open, onOpen, onClose, reactions }) => {
	const nanniesByID = useNanniesById();

	const content = reactions
		?.sort((a, b) => {
			if (
				a.status === DienstNannyStatus.Available &&
				b.status !== DienstNannyStatus.Available
			)
				return -1;
			if (
				a.status !== DienstNannyStatus.Available &&
				b.status === DienstNannyStatus.Available
			)
				return 1;
			if (a.status === b.status) return 0;
			return a.status < b.status ? -1 : 1;
		})
		.map((reaction) => (
			<Table.Row key={reaction.id}>
				<Table.Cell collapsing>{reaction.nannyId}</Table.Cell>
				<Table.Cell collapsing>{nanniesByID[reaction.nannyId]?.name}</Table.Cell>
				<Table.Cell collapsing>
					<Label
						style={{
							backgroundColor: getNannyStatusColor(reaction.status),
							color: getNannyStatusFontColor(reaction.status)
						}}
					>
						{getDienstNannyStatusLabel(reaction.status)}
					</Label>
				</Table.Cell>
				<Table.Cell>{reaction.remark}</Table.Cell>
			</Table.Row>
		));

	return (
		<Modal open={open} onOpen={onOpen} onClose={onClose}>
			<Modal.Header>Reacties voor deze dienst</Modal.Header>
			<Modal.Content>
				{reactions?.length > 0 ? (
					<Table compact style={{ marginTop: 0 }}>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell collapsing>Nanny ID</Table.HeaderCell>
								<Table.HeaderCell collapsing>Naam</Table.HeaderCell>
								<Table.HeaderCell collapsing>Reactie</Table.HeaderCell>
								<Table.HeaderCell>Opmerkingen</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>{content}</Table.Body>
					</Table>
				) : (
					<p style={{ marginTop: 0 }}>Er zijn nog geen reacties op deze dienst.</p>
				)}
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={onClose}>Terug</Button>
			</Modal.Actions>
		</Modal>
	);
};

export const ReactionsButton = ({ reactions, loading }) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<ReactionsModal
				open={open}
				onOpen={() => setOpen(true)}
				onClose={() => setOpen(false)}
				reactions={reactions}
			/>
			<div style={{ display: "flex", justifyContent: "flex-end" }}>
				<Button
					icon
					labelPosition="right"
					onClick={() => setOpen(true)}
					compact
					loading={loading}
				>
					Reacties van nannies bekijken
					<Icon name="long arrow alternate right" />
				</Button>
			</div>
		</>
	);
};
