import moment from "moment";
import React from "react";
import { DateInput } from "semantic-ui-calendar-react";
import { Form } from "semantic-ui-react";

export const DATEFORMAT = "YYYY-MM-DD";
export const DATEDISPLAYFORMAT = "DD-MM-YYYY";

export const DatePicker = ({ onChange, value, disabled, label, min, max, ...rest }) => {
	return disabled ? (
		<Form.Input label={label} disabled value={value.format(DATEDISPLAYFORMAT)} />
	) : (
		<DateInput
			label={label}
			animation="none"
			minDate={min ? min.format(DATEDISPLAYFORMAT) : null}
			maxDate={max ? max.format(DATEDISPLAYFORMAT) : null}
			value={value && value.format(DATEDISPLAYFORMAT)}
			onChange={(e, { value }) => {
				onChange(moment(value, DATEDISPLAYFORMAT));
			}}
			dateFormat={DATEDISPLAYFORMAT}
			localization="nl"
			{...rest}
		/>
	);
};
