import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNannySubscriptions } from "../redux/modules/NannySubscriptions";

export const useNannySubscriptions = () => {
	const dispatch = useDispatch();

	const nannySubscriptions = useSelector((state) => state.nannySubscriptions);

	React.useEffect(() => {
		if (nannySubscriptions.nannySubscriptions) return;
		if (nannySubscriptions.loading) return;

		dispatch(getNannySubscriptions());
	}, [dispatch]);

	return nannySubscriptions.nannySubscriptions;
};
