import fileDownload from "js-file-download";
import moment from "moment";
import {
	DienstSource,
	DienstType,
	getDienstNannyId,
	getDienstNannyName,
	getDienstSourceLabel,
	getDienstStatusLabel,
	getDienstTypeLabel,
	isCancelled
} from "../../constants";

const allColumns = [
	{
		header: "date",
		getLabel: (dienst, nanniesByID, familiesByID, confirmed, month) => dienst.date
	},
	{
		header: "dag van de week",
		getLabel: (dienst, nanniesByID, familiesByID, confirmed, month) =>
			moment(dienst.date, "YYYY-MM-DD").format("dddd")
	},
	{
		header: "nanny_id",
		getLabel: (dienst, nanniesByID, familiesByID, confirmed, month) => getDienstNannyId(dienst)
	},
	{
		header: "nanny",
		getLabel: (dienst, nanniesByID, familiesByID, confirmed, month) =>
			getDienstNannyName(dienst, nanniesByID)
	},
	{
		header: "customer_id",
		getLabel: (dienst, nanniesByID, familiesByID, confirmed, month) => dienst.familyId
	},
	{
		header: "gezin",
		getLabel: (dienst, nanniesByID, familiesByID, confirmed, month) =>
			familiesByID?.[dienst.familyId] ? familiesByID[dienst.familyId].name : "ONBEKEND"
	},
	{
		header: "lastminute",
		getLabel: (dienst, nanniesByID, familiesByID, confirmed, month) =>
			dienst.type === DienstType.LastMinute ? "x" : ""
	},
	{
		header: "nextinvoice",
		getLabel: (dienst, nanniesByID, familiesByID, confirmed, month) => ""
	},
	{
		header: "inschrijfkosten",
		getLabel: (dienst, nanniesByID, familiesByID, confirmed, month) => ""
	},
	{
		header: "bevestigd op",
		getLabel: (dienst, nanniesByID, familiesByID, confirmed, month) => {
			if (!confirmed[dienst.nannyId]) return "";
			if (!confirmed[dienst.nannyId].toDate) return "Ja (oude app)";

			const date = confirmed[dienst.nannyId].toDate();
			return moment(date).format("DD-MM-YYYY HH:mm");
		}
	},
	{
		header: "type dienst",
		getLabel: (dienst, nanniesByID, familiesByID, confirmed, month) =>
			getDienstSourceLabel(dienst)
	},
	{
		header: "status",
		getLabel: (dienst, nanniesByID, familiesByID, confirmed, month) =>
			getDienstStatusLabel(dienst.status)
	},
	{
		header: "comments",
		getLabel: (dienst, nanniesByID, familiesByID, confirmed, month) => dienst.comment || ""
	},
	{
		header: "last minute geannuleerd",
		getLabel: (dienst) => (isCancelled(dienst.status) ? "x" : "")
	}
];

// date	nanny	gezin	customer_id	lastminute	nextinvoice	inschrijfkosten	comments
export const exportActualDiensten = (
	actualDiensten,
	nanniesByID,
	familiesByID,
	confirmed,
	onlyConfirmed,
	month,
	lastExport
) => {
	let columns = allColumns.filter((col) => !onlyConfirmed || col.header !== "bevestigd");

	const headerArr = columns.map((col) => col.header);
	const data = [headerArr.join(";")];

	const sortedDiensten = [...actualDiensten].sort((a, b) => {
		if (a.familyId < b.familyId) return -1;
		if (b.familyId < a.familyId) return 1;
		if (a.date < b.date) return -1;
		if (a.date > b.date) return 1;
		return 0;
	});

	const filteredDiensten = sortedDiensten.filter((dienst) => {
		const nannyId = getDienstNannyId(dienst);
		if (!nannyId) return false;

		if (isCancelled(dienst.status)) {
			let keepCancelled = false;
			if (dienst.cancelledWithin72h) {
				keepCancelled = true;
			}

			if (dienst.type === DienstType.LastMinute) {
				keepCancelled = true;
			}

			// Skip cancelled diensten
			if (!keepCancelled) return false;
		}

		let confirmDate = confirmed[nannyId];
		if (onlyConfirmed && !confirmDate) return false; // not confirmed, skip
		if (lastExport && confirmDate.toDate() < lastExport.toDate()) return false; // Filled before last export

		return true;
	});

	const fixedDaysPerFamily = {};
	for (let i = 0; i < filteredDiensten.length; i++) {
		const { familyId, source, date } = filteredDiensten[i];
		if (source === DienstSource.Subscription) {
			if (!(familyId in fixedDaysPerFamily)) {
				fixedDaysPerFamily[familyId] = [];
			}

			fixedDaysPerFamily[familyId].push(date);
		}
	}

	let dienstenWithoutDuplicated = [];
	for (let i = 0; i < filteredDiensten.length; i++) {
		const { familyId, date, type } = filteredDiensten[i];
		if (type === DienstType.Extra && fixedDaysPerFamily[familyId]?.includes(date)) {
			console.log("Dubbele dienst gevonden: ", familyId, date, fixedDaysPerFamily[familyId]);
		} else {
			dienstenWithoutDuplicated.push(filteredDiensten[i]);
		}
	}

	for (let i = 0; i < dienstenWithoutDuplicated.length; i++) {
		const curDienst = dienstenWithoutDuplicated[i];

		let row = [];
		for (let c = 0; c < columns.length; c++) {
			const column = columns[c];
			const label = column.getLabel(curDienst, nanniesByID, familiesByID, confirmed, month);

			row.push(label);
		}

		// Wrap in quotes
		row = row.map((val) => '"' + val + '"');

		data.push(row.join(";"));
	}

	let fn;
	if (onlyConfirmed) {
		fn = "diensten--" + month + "-BEVESTIGD.csv";
	} else {
		fn = "diensten--" + month + ".csv";
	}

	fileDownload(data.join("\n"), fn);
};
