import React, { useEffect, useState } from "react";
import moment from "moment";
import { Segment, Statistic, Icon, Button, Checkbox } from "semantic-ui-react";
import styles from "./ExportDaysTab.module.css";

import MonthDropdown from "../MonthDropdown";

import { exportRecords } from "./utils";
import { exportActualDiensten } from "./exportActualDiensten";
import { useDispatch, useSelector } from "react-redux";
import { getConfirmedMonths, getRecords } from "../../redux/modules/Records";
import { getLastExport, setLastExport } from "../../redux/modules/Exports";
import { useNanniesById } from "../../hooks/useNannies";
import { useFamiliesById } from "../../hooks/useFamilies";
import { useActualDiensten } from "../../hooks/useActualDiensten";

const ExportDaysTab = () => {
	const dispatch = useDispatch();
	const [month, setMonth] = useState(moment().date(1).format("YYYY_MM"));
	const [definitiveExportOld, setDefinitiveExportOld] = React.useState(false);
	const [definitiveExport, setDefinitiveExport] = React.useState(false);

	const records = useSelector((state) => state.records);
	const recentExports = useSelector((state) => state.exports);

	const nanniesById = useNanniesById();
	const familiesById = useFamiliesById();
	const { actualDiensten, loading: actualDienstenLoading } = useActualDiensten(
		null,
		month,
		month,
		false,
		true,
		true,
		false,
		true
	);

	useEffect(() => {
		if (!records[month]) {
			dispatch(getRecords(month));
			dispatch(getConfirmedMonths(month));
		}

		if (!recentExports[month]) {
			dispatch(getLastExport(month));
		}
	}, [month, records, recentExports, dispatch]);

	let loading = false;
	if (!records[month]) {
		loading = true;
	} else {
		if (records[month].loading) loading = true;
		if (records[month].loadingConfirmed) loading = true;
	}
	if (!familiesById) loading = true;
	if (!nanniesById) loading = true;
	if (!recentExports[month] || recentExports[month].loading) loading = true;
	if (actualDienstenLoading) loading = true;

	let totalDays = 0;
	let totalConfirmed = 0;
	let sinceLastExport = 0;

	if (!loading) {
		for (let i = 0; i < records[month].records.length; i++) {
			const curValue = records[month].records[i];
			if (!curValue.dates) continue;

			if (recentExports[month].lastExport) {
				for (const [key, value] of Object.entries(curValue.dates)) {
					if (!value.toDate) continue;
					if (value.toDate() < recentExports[month].lastExport.toDate()) continue;

					sinceLastExport += 1;
				}
			}

			const nrDays = Object.keys(curValue.dates).length;
			totalDays += nrDays;
			if (curValue.nannyId in records[month].confirmed) {
				totalConfirmed += nrDays;
			}
		}
	}

	const handleExportDienstenClick = (onlyConfirmed, sinceLastExport) => () => {
		exportActualDiensten(
			actualDiensten,
			nanniesById,
			familiesById,
			records[month]?.confirmed,
			sinceLastExport ? true : onlyConfirmed,
			month,
			sinceLastExport ? recentExports.lastExport : null
		);
	};

	const handleExportClick = (onlyConfirmed, sinceLastExport) => () => {
		if (definitiveExportOld) dispatch(setLastExport(month));

		exportRecords(
			records[month],
			nanniesById,
			familiesById,
			onlyConfirmed,
			month,
			sinceLastExport ? recentExports.lastExport : null
		);
	};

	let lastExportLabel = null;
	if (recentExports[month] && recentExports[month].done) {
		lastExportLabel = "Nooit";
		if (recentExports[month].lastExport) {
			lastExportLabel = moment(recentExports[month].lastExport.toDate()).format(
				"DD-MM-YYYY HH:mm"
			);
		}
	}

	let markCheckboxLabel = 'Markeer export als "definitief"';
	if (lastExportLabel) markCheckboxLabel += " (laatste keer: " + lastExportLabel + ")";

	return (
		<div className={styles.container}>
			<div className={styles.item}>
				<Segment.Group className={styles.segmentGroup}>
					<Segment className={styles.header}>Selecteer maand</Segment>
					<Segment className={styles.content}>
						<MonthDropdown month={month} setMonth={setMonth} />
					</Segment>
				</Segment.Group>
				<Segment.Group>
					<Segment className={styles.header}>Exporteer diensten</Segment>
					<Segment className={styles.content} loading={loading}>
						<Checkbox
							label={markCheckboxLabel}
							toggle
							checked={definitiveExport}
							onChange={() => setDefinitiveExport(!definitiveExport)}
						/>
						<br />
					</Segment>
					<Segment
						className={styles.content}
						loading={loading}
						style={{ textAlign: "right" }}
					>
						<Button
							icon
							labelPosition="right"
							disabled={loading}
							className={styles.exportButton}
							onClick={handleExportDienstenClick(false, false)}
						>
							Exporteer ALLE diensten
							<Icon name="download" />
						</Button>
						<Button
							icon
							labelPosition="right"
							disabled={loading || definitiveExport}
							className={styles.exportButton}
							onClick={handleExportDienstenClick(true, false)}
						>
							Exporteer diensten van BEVESTIGDE nannies
							<Icon name="download" />
						</Button>
						<Button
							icon
							labelPosition="right"
							disabled={loading || definitiveExport}
							className={styles.exportButton}
							onClick={handleExportDienstenClick(true, true)}
						>
							Exporteer bevestigde diensten sinds LAATSE export
							<Icon name="download" />
						</Button>
					</Segment>
				</Segment.Group>
				<Segment.Group>
					<Segment className={styles.header}>(OUDE APP) Exporteren</Segment>
					<Segment className={styles.content} loading={loading}>
						<Checkbox
							label={markCheckboxLabel}
							toggle
							checked={definitiveExportOld}
							onChange={() => setDefinitiveExportOld(!definitiveExportOld)}
						/>
						<br />
					</Segment>
					<Segment
						className={styles.content}
						loading={loading}
						style={{ textAlign: "right" }}
					>
						<Button
							icon
							labelPosition="right"
							disabled={loading}
							className={styles.exportButton}
							onClick={handleExportClick(false, false)}
						>
							Exporteer ALLE dagen ({totalDays})
							<Icon name="download" />
						</Button>
						<Button
							icon
							labelPosition="right"
							disabled={loading || definitiveExportOld}
							className={styles.exportButton}
							onClick={handleExportClick(true, false)}
						>
							Exporteer BEVESTIGDE dagen ({totalConfirmed})
							<Icon name="download" />
						</Button>
						<Button
							icon
							labelPosition="right"
							disabled={loading || !recentExports[month].lastExport}
							className={styles.exportButton}
							onClick={handleExportClick(false, true)}
						>
							Exporteer ALLE dagen sinds laatste export ({sinceLastExport})
							<Icon name="download" />
						</Button>
					</Segment>
				</Segment.Group>
			</div>
		</div>
	);
};

export default ExportDaysTab;
