import React from "react";
import styles from "./SubscriptionDienstenTab.module.css";
import { DienstenTable } from "../DienstenTable";
import { Dimmer, Form, Loader } from "semantic-ui-react";
import MonthDropdown from "../MonthDropdown";
import moment from "moment";
import { useSubscriptionDiensten } from "../../hooks/useSubscriptionDiensten";
import { CancelDienstButton } from "../CancelDienstButton";
import { columns } from "../DienstenTable/columns";
import { AddEditModal } from "./AddEditModal";
import { useModal } from "../../hooks/useModal";
import { useActualDiensten } from "../../hooks/useActualDiensten";

export const SubscriptionDienstenTab = () => {
	const [month, setMonth] = React.useState(moment().date(1).format("YYYY_MM"));

	const { actualDiensten: diensten, loading: loadingDiensten } = useActualDiensten(
		null,
		month,
		month,
		true,
		false,
		true,
		true,
		true
	);
	const { modalProps, openModal } = useModal(diensten);

	const loading = loadingDiensten;

	return (
		<div className={styles.container}>
			{loading && (
				<Dimmer active>
					<Loader />
				</Dimmer>
			)}
			<AddEditModal {...modalProps} />
			<div className={styles.headerContainer}>
				<div className={styles.selectMonthContainer}>
					<Form>
						<Form.Group inline style={{ marginBottom: 0 }}>
							<MonthDropdown
								fluid={false}
								month={month}
								setMonth={setMonth}
								monthsAhead={12}
							/>
						</Form.Group>
					</Form>
				</div>
				<div style={{ display: "flex" }}>
					<CancelDienstButton />
				</div>
			</div>
			<DienstenTable
				diensten={diensten}
				columns={columns.filter((col) => !col.name.includes("reaction"))}
				deletable={false}
				openModal={openModal}
			/>
		</div>
	);
};
