import { createSlice } from "@reduxjs/toolkit";

import firebase from "firebase/app";
import "firebase/firestore";

const nannySubscriptionsSlice = createSlice({
	name: "nannySubscriptions",
	initialState: {
		loading: false,
		nannySubscriptions: null
	},
	reducers: {
		getNannySubscriptionsLoading: (state) => {
			state.loading = true;
		},
		getNannySubscriptionsSuccess: (state, action) => {
			const { data } = action.payload;
			state.nannySubscriptions = data;
			state.loading = false;
		}
	}
});

export default nannySubscriptionsSlice.reducer;

const { getNannySubscriptionsLoading, getNannySubscriptionsSuccess } =
	nannySubscriptionsSlice.actions;

export const getNannySubscriptions = () => async (dispatch) => {
	dispatch(getNannySubscriptionsLoading());

	console.log("Getting nannySubscriptions...");

	firebase
		.firestore()
		.collection("nannySubscriptions")
		.onSnapshot((querySnapshot) => {
			let data = {};
			querySnapshot.forEach((doc) => {
				data[doc.id] = doc.data();
			});

			dispatch(getNannySubscriptionsSuccess({ data }));
		});
};
