import React from "react"

import { Table } from "semantic-ui-react"
import { amountTypes, enableAmountTypeForTariff, getAmountTypeLabel, getTariffLabel } from '../../constants'

const TariffTable = ({ tariffs, onRowClick }) => {
    return (
        <Table selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Tariefcode</Table.HeaderCell>
                    <Table.HeaderCell>Naam</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    {[...Array(amountTypes.Last).keys()].map((amountType) => <Table.HeaderCell key={amountType}>{getAmountTypeLabel(amountType)}</Table.HeaderCell>)}
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {tariffs && tariffs.map((tariff) => (
                    <Table.Row onClick={() => onRowClick(tariff.id)}>
                        <Table.Cell>{tariff.id}</Table.Cell>
                        <Table.Cell>{tariff.label}</Table.Cell>
                        <Table.Cell>{getTariffLabel(tariff.type)}</Table.Cell>
                        {tariff.amounts.map((amount, amountType) => (
                            <Table.Cell>
                                {enableAmountTypeForTariff(amountType, tariff.type) ? (
                                    "€ " + amount.toFixed(2)
                                ) : (
                                    "Nvt"
                                )}
                            </Table.Cell>
                        ))}
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    )
};

export default TariffTable;