import React, { useEffect } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";

export const useDienstenListener = (
	familyId,
	fromMonthStr,
	toMonthStr,
	onlyLocalChanges = false
) => {
	const [loading, setLoading] = React.useState(true);
	const [diensten, setDiensten] = React.useState([]);

	useEffect(() => {
		setLoading(true);

		const collectionRef = firebase.firestore().collection("diensten");

		let allFuture = false;
		if (!fromMonthStr && !toMonthStr) {
			allFuture = true;
		}

		let start;
		if (fromMonthStr) {
			start = moment(fromMonthStr, "YYYY_MM").startOf("month").format("YYYY-MM-DD");
		} else if (allFuture) {
			start = moment().format("YYYY-MM-DD");
		}

		let query;
		if (start) {
			query = collectionRef.where("date", ">=", start);
		}

		if (toMonthStr) {
			const end = moment(toMonthStr, "YYYY_MM").endOf("month").format("YYYY-MM-DD");
			query = query ? query.where("date", "<=", end) : collectionRef.where("date", "<=", end);
		}

		if (familyId) {
			query = query
				? query.where("familyId", "==", familyId)
				: collectionRef.where("familyId", "==", familyId);
		}

		let options = {};
		if (onlyLocalChanges) {
			options = { source: "cache" };
			console.log("Only local changes", options);
		}

		return query.onSnapshot(options, (querySnapshot) => {
			console.log("Diensten snapshot came in");

			let data = [];
			querySnapshot.forEach((doc) => {
				const { creationTimestamp, updateTimestamp, pushTimestamp, date, ...rest } =
					doc.data();
				if (!date) return;

				let dataObj = {
					id: doc.id,
					creationTimestamp: creationTimestamp && creationTimestamp.toDate(),
					updateTimestamp: updateTimestamp && updateTimestamp.toDate(),
					pushTimestamp: pushTimestamp && pushTimestamp.toDate(),
					date,
					displayDate: moment(date, "YYYY-MM-DD").format("LL (dd)"),
					...rest
				};

				data.push(dataObj);
			});

			setDiensten(data);
			setLoading(false);
		});
	}, [familyId, fromMonthStr, onlyLocalChanges, toMonthStr]);

	return { loading, diensten };
};
