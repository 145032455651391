import React from "react"
import { Input, Segment, Menu } from "semantic-ui-react"

import { useFamilies } from "../../hooks/useFamilies";
import { useNannies } from "../../hooks/useNannies";
import { useCredits } from "../../hooks/useCredits";

import styles from "./FamiliesTab.module.css";
import FamilyOverview from "./FamilyOverview";

const FamiliesTab = () => {
    const [activeFamily, setActiveFamily] = React.useState(null);
    const [searchText, setSearchText] = React.useState("");

    const families = useFamilies();
    const nannies = useNannies();
    const credits = useCredits();

    const handleFamilyClick = (fam) => () => {
        setActiveFamily(activeFamily === fam ? null : fam);
    }

    let displayFamilies = families;
    const handleChange = (e, { value }) => setSearchText(value);
    if (searchText && searchText !== "") {
        displayFamilies = [...families].filter((fam) => {
            if (!fam.name) return false;
            return fam.name.toUpperCase().includes(searchText.toUpperCase());
        })
    }

    let familyNannies = [];
    if (activeFamily) {
        familyNannies = nannies.filter((nanny) => {
            if (!nanny.families) return false;
            return nanny.families.includes(activeFamily.id);
        })
    }

    return (
        <>
            <Input className={styles.searchInput} placeholder="Zoek families..." onChange={handleChange} value={searchText} fluid/>
            <div className={styles.container}>
                <div className={styles.item}>
                    <Segment.Group className={styles.segmentGroup}>
                        <Segment className={styles.header}>
                            Families
                        </Segment>
                        <Menu className={styles.menu} as={Segment} fluid vertical loading={!families}>
                            {displayFamilies && displayFamilies.map((fam, idx) => (
                                <Menu.Item key={idx} active={fam === activeFamily} onClick={handleFamilyClick(fam)}>
                                    {fam.name}
                                </Menu.Item>
                            ))}
                        </Menu>
                    </Segment.Group>
                </div>
                {activeFamily && <FamilyOverview familyId={activeFamily.id} name={activeFamily.name} nannies={familyNannies} credits={credits[activeFamily.id]} />}
            </div>
        </>
    )
}

export default FamiliesTab;