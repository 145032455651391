import { Form } from "semantic-ui-react";
import { FormGroup } from "../FormGroup";

export const SendPushFormGroup = ({ label, disabled, pushData, setPushData }) => {
	return (
		<FormGroup
			label={label}
			checked={pushData.send}
			onToggleCheck={(checked) => setPushData((old) => ({ ...old, send: checked }))}
			collapsable
			disabled={disabled}
		>
			{pushData.send && (
				<Form.Input
					label="Titel van pushbericht"
					value={pushData.title}
					onChange={(e, { value }) => setPushData((old) => ({ ...old, title: value }))}
					disabled={disabled}
				/>
			)}
			{pushData.send && (
				<Form.TextArea
					label="Inhoud van pushbericht"
					value={pushData.content}
					onChange={(e, { value }) => setPushData((old) => ({ ...old, content: value }))}
					disabled={disabled}
				/>
			)}
		</FormGroup>
	);
};
