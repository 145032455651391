import firebase from "firebase";
import "firebase/firestore";

import { readFile } from "../../reader";

const COLUMNIDX = {
	NANNYID: 0,
	NANNYNAME: 1,
	MAIL: 2,
	LAST: 3
};

const columns = [
	{ index: COLUMNIDX.NANNYID, required: true, upper: true },
	{ index: COLUMNIDX.NANNYNAME, required: true, upper: false },
	{ index: COLUMNIDX.MAIL, required: true, upper: false }
];

const importNanniesFile = (file, nannies) => {
	return new Promise((resolve, reject) => {
		var reader = new FileReader();
		reader.onload = async function (progressEvent) {
			//convert
			var oldNanniesByID = nannies.reduce((obj, item) => ((obj[item.id] = item), obj), {});

			const newNannies = {};
			const success = readFile(this.result, columns, reject, (lineArray) => {
				let nannyId = lineArray[COLUMNIDX.NANNYID];
				if (nannyId.substring(0, 3) !== "NAN" && nannyId.substring(0, 7) !== "TESTNAN") {
					console.log("nannyId incorrect: " + nannyId);
					return;
				}

				newNannies[nannyId] = {
					name: lineArray[COLUMNIDX.NANNYNAME],
					email: lineArray[COLUMNIDX.MAIL].trim(),
					lastUpdate: firebase.firestore.FieldValue.serverTimestamp()
				};
			});

			if (!success) {
				reject("!success in importNanniesFile");
				return;
			}

			var newNanniesKeys = Object.keys(newNannies);
			if (newNanniesKeys.length <= 10) {
				reject(
					"Minder dan 10 Nannies gevonden in bestand, voor de zekerheid wordt er niet geupload"
				);
				return;
			}

			// for (let key of newNanniesKeys) {
			// 	if (!(key in oldNanniesByID)) continue;
			// 	if (key.includes("TEST")) continue; // allow change testnannies
			// 	if (oldNanniesByID[key].email === newNannies[key].email) continue;

			// 	reject(
			// 		"Email adres van nanny " +
			// 			key +
			// 			" was " +
			// 			oldNanniesByID[key].email +
			// 			" en is nu " +
			// 			newNannies[key].email +
			// 			". Kan niet uploaden, corrigeer het bestand of geef de nanny een nieuw nummer."
			// 	);
			// 	return;
			// }

			const promises = [];
			while (newNanniesKeys.length) {
				const chunk = newNanniesKeys.splice(0, 10);
				const batch = firebase.firestore().batch();

				for (let key of chunk) {
					const ref = firebase.firestore().collection("nannies").doc(key);
					batch.set(ref, newNannies[key], { merge: true });

					if (key in oldNanniesByID) {
						delete oldNanniesByID[key];
					}
				}

				promises.push(batch.commit());

				// 10 per second
				await new Promise((r) => setTimeout(r, 1000));
			}

			var oldNanniesKeys = Object.keys(oldNanniesByID);
			while (oldNanniesKeys.length) {
				const chunk = oldNanniesKeys.splice(0, 250);
				const batch = firebase.firestore().batch();

				for (let key of chunk) {
					if (key.includes("TEST")) continue; // keep testnannies
					const ref = firebase.firestore().collection("nannies").doc(key);
					batch.delete(ref);
				}

				promises.push(batch.commit());

				// 10 per second
				await new Promise((r) => setTimeout(r, 1000));
			}

			Promise.all(promises).then(resolve).catch(reject);
		};

		reader.readAsText(file);
	});
};

export default importNanniesFile;
