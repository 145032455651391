import React, { useCallback, useMemo, useState } from "react";
import { isPlainObject } from "@reduxjs/toolkit";
import { Dimmer, Loader, Modal as SemanticModal } from "semantic-ui-react";

const getItemForId = (items, id) => {
	if (!id) return null;

	let selectedItem = null;
	if (Array.isArray(items)) {
		for (let i = 0; i < items.length; i++) {
			if (items[i].id !== id) continue;
			selectedItem = items[i];
			break;
		}
	} else if (isPlainObject(items)) {
		selectedItem = items[id];
	}

	return selectedItem;
};

export const useModal = (items) => {
	const [open, setOpen] = React.useState(false);
	const [selectedItemId, setSelectedItemId] = React.useState(null);
	const [loading, setLoading] = useState(false);

	const openModal = useCallback((selectedItemId) => {
		setOpen(true);
		setSelectedItemId(selectedItemId);

		console.log("Open modal", selectedItemId);
	}, []);

	const closeModal = useCallback(() => {
		setOpen(false);
		setSelectedItemId(null);

		console.log("Close modal");
	}, []);

	const modalProps = useMemo(() => {
		return {
			item: getItemForId(items, selectedItemId),
			selectedItemId,
			onClose: closeModal,
			open,
			loading,
			setLoading
		};
	}, [closeModal, items, loading, open, selectedItemId]);

	return { modalProps, openModal, closeModal };
};
