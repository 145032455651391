import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { useSubscriptions } from "./useSubscriptions";
import { DienstSource, DienstStatus } from "../constants";
import { useAdjustments } from "./useAdjustments";

const parseDateString = (str) => moment(str, ["DD-MM-YY", "DD-MM-YYYY", "YYYY-MM-DD"]);

const addSubscriptionDienstenForFamily = (
	diensten,
	familyId,
	subscriptions,
	firstOfFromMonth,
	lastOfToMonth,
	adjustments
) => {
	const subscriptionsForFamily = subscriptions[familyId].subscriptions;

	// Add diensten from subscriptions
	let duplicateCheck = {};
	for (let i = subscriptionsForFamily?.length - 1; i >= 0; i--) {
		const subscription = subscriptionsForFamily[i];

		let from = firstOfFromMonth;
		let to = lastOfToMonth;

		const startDateMoment = parseDateString(subscription.startDate);
		if (startDateMoment.isValid()) {
			if (startDateMoment.isAfter(lastOfToMonth)) {
				// Will start after this month has ended
				continue;
			} else if (startDateMoment.isAfter(firstOfFromMonth)) {
				// Will start this month
				from = startDateMoment;
			}
		}

		const endDateMoment = parseDateString(subscription.endDate);
		if (endDateMoment.isValid()) {
			if (endDateMoment.isBefore(firstOfFromMonth)) {
				// Has ended before this month has started
				continue;
			} else if (endDateMoment.isBefore(lastOfToMonth)) {
				// Has ended this month
				to = endDateMoment;
			}
		}

		for (let curdate = from.clone(); curdate.isSameOrBefore(to); curdate.add(1, "days")) {
			const weekDay = curdate.isoWeekday() - 1;
			if (!subscription.days[weekDay]) continue;

			const id = `${curdate.format("YYYYMMDD")}_${familyId}_${
				subscription.nannyId || "NONANNY"
			}`;

			if (duplicateCheck[id]) {
				console.log("Duplicate dienst found: ", id);
				continue;
			}

			duplicateCheck[id] = true;

			let dienst = {
				id,
				date: curdate.format("YYYY-MM-DD"),
				familyId,
				source: DienstSource.Subscription,
				displayDate: curdate.format("LL (dd)"),
				nannyId: subscription.nannyId,
				status: DienstStatus.Confirmed
			};

			if (subscription.times?.[weekDay]?.from && subscription.times?.[weekDay]?.to) {
				dienst.startTime = subscription.times[weekDay].from;
				dienst.endTime = subscription.times[weekDay].to;
			}

			if (adjustments[id]?.adjustment) {
				dienst = { ...dienst, ...adjustments[id].adjustment };

				if (adjustments[id].adjustment.updateTimestamp) {
					dienst.updateTimestamp = adjustments[id].adjustment.updateTimestamp.toDate();
				}
			}

			diensten.push(dienst);
		}
	}
};

export const useSubscriptionDiensten = (familyId, fromMonthStr, toMonthStr) => {
	const subscriptions = useSubscriptions();

	const { loading, adjustments } = useAdjustments(familyId, fromMonthStr, toMonthStr);

	const diensten = useMemo(() => {
		if (!subscriptions) return [];
		if (!fromMonthStr) return [];
		if (!toMonthStr) return [];

		// This family has no subscriptions
		if (familyId && !subscriptions[familyId]) return [];

		let fromMonth = moment(fromMonthStr, "YYYY_MM");
		if (!fromMonth.isValid()) return [];

		let toMonth = moment(toMonthStr, "YYYY_MM");
		if (!toMonth.isValid()) return [];

		const firstOfFromMonth = fromMonth.startOf("month");
		const lastOfToMonth = toMonth.endOf("month");

		const families = familyId ? [familyId] : Object.keys(subscriptions);
		let diensten = [];
		for (let i = 0; i < families.length; i++) {
			const familyId = families[i];
			if (!familyId) continue;

			addSubscriptionDienstenForFamily(
				diensten,
				familyId,
				subscriptions,
				firstOfFromMonth,
				lastOfToMonth,
				adjustments
			);
		}

		return diensten;
	}, [familyId, fromMonthStr, toMonthStr, subscriptions, adjustments]);

	return { loading, diensten };
};
