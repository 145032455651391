import { useEffect, useMemo } from "react";
import { useNannies } from "./useNannies";
import { useFamilies } from "./useFamilies";
import { useDispatch, useSelector } from "react-redux";
import { setKnownNannies } from "../redux/modules/Nannies";

export const useKnownNanniesListener = () => {
	const dispatch = useDispatch();
	const nannies = useNannies();
	const families = useFamilies();

	useEffect(() => {
		if (!families || !nannies) return;

		const knownNannies = {};
		for (let i = 0; i < nannies.length; i++) {
			const nanny = nannies[i];
			for (let j = 0; j < nanny.families?.length; j++) {
				const familyId = nanny.families[j];
				if (!knownNannies[familyId]) knownNannies[familyId] = [];
				knownNannies[familyId].push(nanny);
			}
		}

		dispatch(setKnownNannies({ data: knownNannies }));
	}, [dispatch, families, nannies]);
};

export const useKnownNannies = () => {
	const knownNannies = useSelector((state) => state.nannies.knownNanniesPerFamily);
	return knownNannies || [];
};
