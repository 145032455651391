export const parseLine = (lineArray, columns) => {
	let valid = true;
	for (let c = 0; c < columns.length; c++) {
		if (c < lineArray.length) {
			lineArray[c] = lineArray[c].replace('"', "");
			lineArray[c] = lineArray[c].trim();
			if (columns[c].upper) lineArray[c] = lineArray[c].toUpperCase();
			if (lineArray[c] !== "") continue;
		}

		// Required column is empty, not valid
		if (!columns[c].required) continue;
		valid = false;
		break;
	}

	return valid;
};

export const getDelimiter = (line, nrcolumns) => {
	let headerarr = line.split(";");
	if (headerarr.length >= nrcolumns) return ";";

	headerarr = line.split(",");
	if (headerarr.length >= nrcolumns) return ",";

	return null;
};

function splitWithQuotes(line, delimiter) {
	const result = [];
	let currentField = "";
	let insideQuotes = false;

	for (let i = 0; i < line.length; i++) {
		const char = line[i];

		if (char === '"') {
			insideQuotes = !insideQuotes;
		} else if (char === delimiter && !insideQuotes) {
			result.push(currentField.trim());
			currentField = "";
		} else {
			currentField += char;
		}
	}

	result.push(currentField.trim());
	return result;
}

export const readFile = (result, columns, reject, processLineFunc) => {
	if (!result) return false;
	if (!processLineFunc) return false;

	// By lines
	var lines = result.split(/[\r\n]+/g);
	if (lines.length <= 1) {
		reject("1 regel of minder in bestand!");
		return false;
	}

	let delimiter = getDelimiter(lines[0], columns.length);
	if (!delimiter) {
		reject(
			`Aantal gevonden kolommen in bestand niet correct (moeten ${columns.length} kolommen zijn) óf separator in CSV is niet , of ;`
		);
		return false;
	}

	for (var line = 1; line < lines.length; line++) {
		const linearr = splitWithQuotes(lines[line], delimiter);

		let emptyLine = true;
		for (let i = 0; i < linearr.length; i++) {
			if (!linearr[i]) continue;
			if (linearr[i] === "") continue;
			emptyLine = false;
			break;
		}
		if (emptyLine) continue;

		const valid = parseLine(linearr, columns);
		if (!valid) {
			console.log("Invalid line: ", linearr);
			continue;
		}

		processLineFunc(linearr);
	}

	return true;
};
