import { combineReducers } from "redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import authReducer from "./modules/Auth";
import familiesReducer from "./modules/Families";
import nanniesReducer from "./modules/Nannies";
import recordsReducer from "./modules/Records";
import exportsReducer from "./modules/Exports";
import tariffsReducer from "./modules/Tariffs";
import creditsReducer from "./modules/Credits";
import subscriptionsReducer from "./modules/Subscriptions";
import nannySubscriptionsReducer from "./modules/NannySubscriptions";

const rootReducer = combineReducers({
	auth: authReducer,
	families: familiesReducer,
	nannies: nanniesReducer,
	records: recordsReducer,
	exports: exportsReducer,
	tariffs: tariffsReducer,
	credits: creditsReducer,
	subscriptions: subscriptionsReducer,
	nannySubscriptions: nannySubscriptionsReducer
});

const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware({
		serializableCheck: false
	})
});

export default store;
