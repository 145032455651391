import React from "react";
import styles from "./ImportTab.module.css";

import importNanniesFile from "./ImportNannies";
import importFamiliesFile from "./ImportFamilies";
import FileImportForm from "../FileImportForm";
import { useNannies } from "../../hooks/useNannies";
import { useFamilies } from "../../hooks/useFamilies";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { useNannySubscriptions } from "../../hooks/useNannySubscriptions";
import { importSubscriptionsFile } from "../InvoiceTab/importSubscriptions";

const ImportTab = () => {
	const nannies = useNannies();
	const families = useFamilies();
	const subscriptions = useSubscriptions();
	const nannySubscriptions = useNannySubscriptions();

	const [nanniesState, setNanniesState] = React.useState("idle");
	const [nanniesError, setNanniesError] = React.useState("");
	const [familiesState, setFamiliesState] = React.useState("idle");
	const [familiesError, setFamiliesError] = React.useState("");
	const [schedulerState, setSchedulerState] = React.useState("idle");
	const [schedulerError, setSchedulerError] = React.useState("");

	const handleNanniesImport = (file) => {
		setNanniesState("loading");
		importNanniesFile(file, nannies)
			.then(() => {
				setNanniesState("success");
			})
			.catch((err) => {
				console.log(err);
				setNanniesState("error");
				setNanniesError(err);
			});
	};

	const handleFamiliesImport = (file) => {
		setFamiliesState("loading");
		importFamiliesFile(file, families, nannies)
			.then(() => {
				setFamiliesState("success");
			})
			.catch((err) => {
				console.log(err);
				setFamiliesState("error");
				setFamiliesError(err);
			});
	};

	const handleSchedulerImport = (file) => {
		setSchedulerState("loading");
		importSubscriptionsFile(file, subscriptions, nannySubscriptions, true)
			.then(() => {
				setSchedulerState("success");
			})
			.catch((err) => {
				console.log(err);
				setSchedulerState("error");
				setSchedulerError(err);
			});
	};

	return (
		<div className={styles.container}>
			<div className={styles.item}>
				<FileImportForm
					name="Nannies"
					loading={nanniesState === "loading"}
					error={nanniesState === "error"}
					errorMessage={nanniesError}
					success={nanniesState === "success"}
					onSubmit={handleNanniesImport}
				/>
				<FileImportForm
					name="Gezinnen"
					loading={familiesState === "loading"}
					error={familiesState === "error"}
					errorMessage={familiesError}
					success={familiesState === "success"}
					onSubmit={handleFamiliesImport}
				/>
				<FileImportForm
					name="Scheduler"
					loading={schedulerState === "loading"}
					error={schedulerState === "error"}
					errorMessage={schedulerError}
					success={schedulerState === "success"}
					onSubmit={handleSchedulerImport}
				/>
			</div>
		</div>
	);
};

export default ImportTab;
