import { createSlice } from "@reduxjs/toolkit"
import firebase from 'firebase/app';
import 'firebase/auth';

const authSlice = createSlice({
    name: 'auth',
    initialState: {authUser: null},
    reducers: {
        setAuthUser: (state, action) => {
            const { authUser, superAdmin } = action.payload;
            state.authUser = authUser;
            state.superAdmin = superAdmin;
        }
    }
})

export const logout = () => async dispatch => {
    firebase.auth().signOut().then(function () {
        // Signed out
        dispatch(authSlice.actions.setAuthUser({authUser: null}));
    }).catch(function (error) {
        // Error signing out
        console.log(error);
    });
}

export const { setAuthUser } = authSlice.actions
export default authSlice.reducer