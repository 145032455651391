import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFamilies } from "../redux/modules/Families";

export const useFamilies = () => {
	const dispatch = useDispatch();

	const families = useSelector((state) => state.families);

	useEffect(() => {
		if (families.families) return;
		if (families.loading) return;

		dispatch(getFamilies());
	}, [dispatch]);

	return families.families;
};

export const useFamiliesById = () => {
	const families = useFamilies();
	return useMemo(
		() => families?.reduce((obj, item) => ((obj[item.id] = item), obj), {}),
		[families]
	);
};
