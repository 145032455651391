import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCredits } from "../redux/modules/Credits";

export const useCredits = () => {
    const dispatch = useDispatch();

    const credits = useSelector((state) => state.credits);

    React.useEffect(() => {
        if (credits.credits) return;
        if (credits.loading) return;
        
        dispatch(getCredits());
    }, [dispatch]);

    return credits.credits;
}