import { createSlice } from "@reduxjs/toolkit"

import firebase from 'firebase/app';
import 'firebase/firestore';

const recordsSlice = createSlice({
    name: 'records',
    initialState: {},
    reducers: {
        getRecordsLoading: (state, action) => {
            const {month} = action.payload;

            if (!state[month]) state[month] = {};
            state[month].loading = true;
        },
        getRecordsSuccess: (state, action) => {
            const {month, records } = action.payload;
            state[month].loading = false;
            state[month].records = records;
        },
        getConfirmedLoading: (state, action) => {
            const {month} = action.payload;

            if (!state[month]) state[month] = {};
            state[month].loadingConfirmed = true;
        },
        getConfirmedSuccess: (state, action) => {
            const { month, confirmed } = action.payload;
            state[month].confirmed = confirmed || {}; 
            state[month].loadingConfirmed = false; 
        }
    }
})

const { 
    getRecordsLoading, 
    getRecordsSuccess, 
    getConfirmedLoading, 
    getConfirmedSuccess 
} = recordsSlice.actions

export default recordsSlice.reducer

export const getConfirmedMonths = (month) => async dispatch => {
    dispatch(getConfirmedLoading({month}));

    firebase.firestore().collection("confirmed").doc(month).onSnapshot((doc) => {
        dispatch(getConfirmedSuccess({month, confirmed: doc.data()}));
    });
}

export const getRecords = (month) => async dispatch => {
    dispatch(getRecordsLoading({month}));

    firebase.firestore().collection("records").where("month", "==", month).onSnapshot((querySnapshot) => {
        let records = [];
        querySnapshot.forEach((doc) => {
            records.push(doc.data());
        });

        dispatch(getRecordsSuccess({month, records}));
    });
}