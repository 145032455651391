import React, { useEffect, useMemo } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";
import { useKnownNannies } from "./useKnownNannies";

export const useReactionsListener = () => {
	const [loading, setLoading] = React.useState(false);
	const [reactions, setReactions] = React.useState([]);

	useEffect(() => {
		setLoading(true);

		return firebase
			.firestore()
			.collection("reactions")
			.onSnapshot((querySnapshot) => {
				console.log("Reaction snapshot came in");

				let data = {};

				querySnapshot.forEach((doc) => {
					const { timestamp, ...rest } = doc.data();
					if (!timestamp) return;

					let dataObj = {
						id: doc.id,
						timestamp: timestamp && timestamp.toDate(),
						timestampStr: timestamp
							? moment(timestamp.toDate()).format("DD-MM-YYYY - HH:mm")
							: "",
						...rest
					};

					if (!data[dataObj.dienstId]) data[dataObj.dienstId] = [];
					data[dataObj.dienstId].push(dataObj);
				});

				//sort reactions on timestamp
				for (let key in data) {
					data[key].sort((a, b) => b.timestamp - a.timestamp);
				}

				setReactions(data);
				setLoading(false);
			});
	}, []);

	return { reactions, loading };
};
