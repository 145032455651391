import React from "react";
import styles from "./PDFFilesTab.module.css";
import firebase from "firebase/app";
import "firebase/storage";

import { Button, Icon, Segment } from "semantic-ui-react";

const PDFFileItem = ({ path, title, onClick, onFileUpload }) => {
	const fileInputRef = React.useRef(null);
	const [loading, setLoading] = React.useState(false);

	const handleFileChange = async () => {
		if (!fileInputRef.current) return;

		setLoading(true);
		await onFileUpload(fileInputRef.current.files[0]);
		setLoading(false);
	};

	return (
		<Segment className={styles.content}>
			<h3>{title}</h3>
			<Button onClick={onClick} icon>
				Bekijk PDF
				<Icon name="file pdf" />
			</Button>{" "}
			<Button
				onClick={() => fileInputRef.current && fileInputRef.current.click()}
				icon
				loading={loading}
			>
				Upload nieuwe PDF
				<Icon name="upload" />
			</Button>
			<input ref={fileInputRef} type="file" hidden onChange={handleFileChange} />
		</Segment>
	);
};
const items = [
	{ title: "Jouw contract met Amsternannies", pdf: "infographics/contract.pdf" },
	{ title: "Ziekmelden bij Amsternannies", pdf: "infographics/ziekmelden.pdf" },
	{ title: "Opzeggen van een vaste oppasdag", pdf: "infographics/opzeggen.pdf" },
	{ title: "Veelgestelde vragen", pdf: "infographics/faq.pdf" }
];

export const PDFFilesTab = ({}) => {
	const handleFileImport = async (path, file) => {
		console.log(path, file);

		const ref = firebase.storage().ref(path);
		try {
			await ref.put(file);
			console.log("Uploaded a blob or file!");
		} catch (error) {
			console.log(error);
		}
	};

	const onFileClick = (path) => {
		const ref = firebase.storage().ref(path);
		ref.getDownloadURL()
			.then((url) => {
				window.open(url, "_blank");
			})
			.catch((error) => {
				// Handle any errors
				console.log(error);
			});
	};

	return (
		<div className={styles.container}>
			{items.map((item) => (
				<PDFFileItem
					key={item.title}
					path={item.pdf}
					title={item.title}
					onClick={() => onFileClick(item.pdf)}
					onFileUpload={(file) => handleFileImport(item.pdf, file)}
				/>
			))}
		</div>
	);
};
