import { createSlice } from "@reduxjs/toolkit"
import firebase from 'firebase/app';
import 'firebase/firestore';

const exportsSlice = createSlice({
    name: 'exports',
    initialState: {},
    reducers: {
        getExportsLoading: (state, action) => {
            const { month } = action.payload;
            
            if (!state[month]) state[month] = {};
            state[month].loading = true;
            state[month].done = false;
        },
        getExportsSuccess: (state, action) => {
            const { lastExport, month } = action.payload;

            if (!state[month]) state[month] = {};

            state[month].loading = false;
            state[month].done = true;
            state[month].lastExport = lastExport;
        },
    }
})

export default exportsSlice.reducer
const { getExportsLoading, getExportsSuccess } = exportsSlice.actions

export const getLastExport = (month) => async dispatch => {
    dispatch(getExportsLoading({month}));

    console.log("Getting exports...");

    firebase.firestore().collection("exports").where("month", "==", month).orderBy("timestamp", "desc").limit(1).get()
        .then(function(querySnapshot) {
            if (querySnapshot.empty) {
                dispatch(getExportsSuccess({lastExport: null, month}));
            } else {
                querySnapshot.forEach(function(doc) {
                    dispatch(getExportsSuccess({lastExport: doc.data().timestamp, month}));
                });
            }
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });
}

export const setLastExport = (month) => async dispatch => {
    const timestamp = firebase.firestore.Timestamp.fromDate(new Date());

    dispatch(
        getExportsSuccess({
            lastExport: timestamp,
            month
        })
    )

    firebase.firestore().collection("exports").add({timestamp, month});
}