import React from "react"
import { Segment, Menu, Table } from "semantic-ui-react"

import styles from "./FamiliesTab.module.css";
import FamilyCredits from "./FamilyCredits";

const FamilyOverview = ({ name, nannies, credits, familyId }) => {
    return (
        <div className={styles.activeFamilyItem}>
            <Segment.Group className={styles.segmentGroup}>
                <Segment className={styles.header}>
                    Nannies bij {name}
                </Segment>
                <Menu className={styles.menu} as={Segment} fluid vertical>
                    {nannies && nannies.map((nanny, idx) => (
                        <Menu.Item key={idx}>
                            {nanny.name}
                        </Menu.Item>
                    ))}
                </Menu>
            </Segment.Group>
            <FamilyCredits credits={credits} familyId={familyId} name={name} />
        </div>
    )
}

export default FamilyOverview;