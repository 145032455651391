import { useEffect, useMemo, useState } from "react";
import { useNannies } from "./useNannies";
import { useDienstenListener } from "./useDiensten";
import { useKnownNannies } from "./useKnownNannies";

export const useUnreadDienstenPerNanny = () => {
	const nannies = useNannies();

	const { diensten, loading: dienstenLoading } = useDienstenListener(null, null, null, true);
	const knownNannies = useKnownNannies();

	const loading =
		dienstenLoading ||
		!nannies ||
		nannies.length === 0 ||
		!knownNannies ||
		Object.keys(knownNannies).length === 0;

	const unreadDiensten = useMemo(() => {
		if (loading) return {};

		console.log("useMemo - Unread diensten per nanny");

		const unreadDienstenPerNanny = {};
		for (let i = 0; i < nannies.length; i++) {
			const nanny = nannies[i];
			unreadDienstenPerNanny[nanny.id] = 0;

			for (let j = 0; j < diensten.length; j++) {
				const dienst = diensten[j];
				if (!dienst.pushTimestamp) continue;

				if (nanny.lastDienstenRead && nanny.lastDienstenRead > dienst.pushTimestamp) {
					// already read
					continue;
				}

				if (dienst.targetAllKnownNannies) {
					if (knownNannies?.[dienst.familyId]?.includes(nanny)) {
						unreadDienstenPerNanny[nanny.id]++;
					}
				} else {
					if (dienst.targetNannies?.includes(nanny.id)) {
						unreadDienstenPerNanny[nanny.id]++;
					}
				}
			}
		}

		return unreadDienstenPerNanny;
	}, [knownNannies, nannies, diensten, loading]);

	return {
		unreadDiensten,
		loading
	};
};

export const useUnreadDienstenForNanny = (nannyId) => {
	const { unreadDiensten, loading } = useUnreadDienstenPerNanny();
	return { unreadCount: unreadDiensten?.[nannyId], loading };
};
