import moment from "moment";
import { DienstStatus, capitalizeFirstLetter } from "../../constants";

export const getDefaultPushContentDienst = (currentDienstData, newDienstData, familiesById) => {
	if (!newDienstData) return { title: "", body: "" };
	if (!familiesById) return { title: "", body: "" };
	if (!newDienstData.familyId) return { title: "", body: "" };

	// Only send push if status changed
	if (currentDienstData?.status === newDienstData.status) return { title: "", body: "" };

	const familyName = familiesById[newDienstData?.familyId]?.name;
	const dateStr = capitalizeFirstLetter(
		moment(newDienstData.date, "YYYY-MM-DD").format("dddd D MMMM")
	);

	let body = `${familyName}\n${dateStr}\n${newDienstData.startTime} tot ${newDienstData.endTime}`;

	let title;
	if (newDienstData.status === DienstStatus.Open) {
		title = `Dienst beschikbaar`;
	} else if (newDienstData.status === DienstStatus.Assigned) {
		title = `Dienst aan jou toegewezen!`;
	} else if (newDienstData.status === DienstStatus.Confirmed) {
		title = "Dienst bevestigd!";
	} else if (newDienstData.status === DienstStatus.Cancelled) {
		// Send push notification only if the dienst was assigned to a nanny
		if (newDienstData.assignedNannyId) {
			title = `Dienst geannuleerd`;
		}
	}

	return { body, title };
};

export const getLatestReactionForDienst = (dienst, reactions) => {
	if (!reactions) return "";
	if (!reactions[dienst.id]) return "";

	const reactionsForDienst = reactions[dienst.id];
	if (reactionsForDienst.length === 0) return "";
	return reactionsForDienst[0];
};

export const getReactionColourBasedOnTimestamp = (timestamp) => {
	const diff = Date.now() - timestamp;

	// Make lighter during 6 hours
	const alpha = Math.min(1, 1 - diff / (1000 * 60 * 60 * 6));
	return `rgba(28, 91, 45, ${alpha})`;
};
