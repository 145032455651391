import React, { useEffect, useState } from "react";

import "firebase/auth";
import "firebase/firestore";

import { Button, Modal, Form, Message } from "semantic-ui-react";
import { TimeInput } from "semantic-ui-calendar-react";

import { useFamiliesById } from "../../hooks/useFamilies";
import { FormGroup } from "../FormGroup";
import { SendPushFormGroup } from "../SendPushFormGroup";
import { ShowOtherDienstenButton } from "../DienstenTab/AddEditModal/ShowOtherDienstenButton";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { DienstSource } from "../../constants";
import { useUnreadDienstenForNanny } from "../../hooks/useUnreadDienstenPerNanny";

export const AddEditModal = ({ open, onClose, item, selectedItemId }) => {
	const familiesByID = useFamiliesById();
	const { unreadCount, loading: unreadLoading } = useUnreadDienstenForNanny(item?.nannyId);

	// Loading state
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	// Algemeen
	const [startTime, setStartTime] = useState("00:00");
	const [endTime, setEndTime] = useState("00:00");

	// Opmerkingen
	const [internalRemarks, setInternalRemarks] = useState("");
	const [remarks, setRemarks] = useState("");

	// Pushbericht
	const [pushData, setPushData] = useState({
		send: false,
		title: "",
		content: ""
	});

	useEffect(() => {
		if (item) {
			setStartTime(item.startTime);
			setEndTime(item.endTime);

			setInternalRemarks(item.internalRemarks || "");
			setRemarks(item.remarks || "");

			setPushData({
				send: false,
				title: "Vaste dienst aangepast",
				content: ""
			});
		}
	}, [item, open]);

	const handleTimeChange = (time, isFrom) => {
		if (isFrom) {
			setStartTime(time);
			if (time > endTime) setEndTime(time);
		} else {
			setEndTime(time);
			if (time < startTime) setStartTime(time);
		}
	};

	const handleConfirm = async () => {
		if (!selectedItemId) return null;

		setLoading(true);
		setError(null);

		const data = {
			familyId: item.familyId,
			nannyId: item.nannyId,
			date: item.date,
			adjustedBy: "admin",
			adjustment: {
				startTime,
				endTime,
				internalRemarks,
				remarks
			},
			timestamp: firebase.firestore.FieldValue.serverTimestamp()
		};

		if (pushData.send) {
			data.adjustment.pushTimestamp = firebase.firestore.FieldValue.serverTimestamp();
		}

		let fbRef = firebase.firestore().collection("adjustments").doc(selectedItemId);

		try {
			await fbRef.set(data, { merge: true });
		} catch (error) {
			console.error("Error adding adjustment document: ", error, data);
			setError("Er is een fout opgetreden bij het opslaan van de dienst.");
			return;
		}

		if (pushData.send) {
			let fbPushData = {
				title: pushData.title,
				content: pushData.content,
				type: "dienst",
				dienstId: fbRef.id,
				dienstDate: item.date,
				badgeCountPerNanny: { [item.nannyId]: unreadCount + 1 },
				timestamp: firebase.firestore.FieldValue.serverTimestamp()
			};

			try {
				firebase.firestore().collection("push").add(fbPushData);
			} catch (error) {
				console.error("Error adding push document: ", error, fbPushData);
			}
		}

		onClose();
	};

	let header = "Vaste dienst aanpassen";

	return (
		<Modal open={open} onClose={onClose}>
			<Modal.Header>{header}</Modal.Header>
			<Modal.Content>
				<Form>
					<FormGroup label="Tijd" collapsable>
						<Form.Group widths="equal">
							<TimeInput
								label="Van"
								value={startTime}
								onChange={(e, { value }) => handleTimeChange(value, true)}
								closable
							/>
							<TimeInput
								label="Tot"
								value={endTime}
								onChange={(e, { value }) => handleTimeChange(value, false)}
								closable
							/>
						</Form.Group>

						<ShowOtherDienstenButton
							familyId={item?.familyId}
							family={familiesByID?.[item?.familyId]}
						/>
					</FormGroup>
					<FormGroup label="Opmerkingen / bijzonderheden" collapsable>
						<Form.TextArea
							label="NAAR NANNIES"
							value={remarks}
							onChange={(e, { value }) => setRemarks(value)}
						/>
						<Form.TextArea
							label="INTERN"
							value={internalRemarks}
							onChange={(e, { value }) => setInternalRemarks(value)}
						/>
					</FormGroup>
					<SendPushFormGroup
						label={"Pushmelding versturen naar nanny van deze dienst"}
						pushData={pushData}
						setPushData={setPushData}
					/>
				</Form>
				{error && <Message error>{error}</Message>}
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={onClose}>Terug</Button>
				<Button
					content="Ok"
					labelPosition="right"
					icon="checkmark"
					onClick={handleConfirm}
					primary
					loading={loading || unreadLoading}
				/>
			</Modal.Actions>
		</Modal>
	);
};
