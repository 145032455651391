import React from "react";
import { Input, Table } from "semantic-ui-react";
import moment from "moment";

import styles from "./NanniesTab.module.css";
import { useNannies } from "../../hooks/useNannies";

const NanniesTab = ({}) => {
	const [sort, setSort] = React.useState("nannyId");
	const [asc, setAsc] = React.useState(true);
	const [searchText, setSearchText] = React.useState("");
	const nannies = useNannies();

	const onSortClick = (newSort) => () => {
		if (sort === newSort) {
			setAsc(!asc);
		} else {
			setSort(newSort);
			setAsc(true);
		}
	};

	let displayNannies = nannies ? [...nannies] : [];
	const handleChange = (e, { value }) => setSearchText(value);
	if (searchText && searchText !== "") {
		displayNannies = displayNannies.filter((nanny) => {
			if (!nanny.name) return false;
			return nanny.name.toUpperCase().includes(searchText.toUpperCase());
		});
	}

	let sortedNannies = [];
	if (sort === "name") {
		sortedNannies = displayNannies.sort((a, b) => {
			if (a.name && !b.name) return -1;
			if (b.name && !a.name) return 1;
			if (!a.name && !b.name) return 0;

			if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
			if (b.name.toUpperCase() < a.name.toUpperCase()) return 1;
			return 0;
		});
	} else if (sort === "nannyId") {
		sortedNannies = displayNannies.sort((a, b) => {
			if (a.id && !b.id) return -1;
			if (b.id && !a.id) return 1;
			if (!a.id && !b.id) return 1;

			if (a.id.charAt(0) === "T" && b.id.charAt(0) !== "T") return 1;
			if (b.id.charAt(0) === "T" && a.id.charAt(0) !== "T") return -1;

			const anumber = Number(a.id.replace(/\D/g, ""));
			const bnumber = Number(b.id.replace(/\D/g, ""));
			if (anumber < bnumber) return -1;
			if (bnumber < anumber) return 1;
			return 0;
		});
	} else if (sort === "login") {
		sortedNannies = displayNannies.sort((a, b) => {
			if (a.lastLogin && !b.lastLogin) return -1;
			if (b.lastLogin && !a.lastLogin) return 1;

			if (a.lastLogin > b.lastLogin) return -1;
			if (b.lastLogin > a.lastLogin) return 1;
			return 0;
		});
	} else if (sort === "confirm") {
		sortedNannies = displayNannies.sort((a, b) => {
			if (a.lastConfirm && !b.lastConfirm) return -1;
			if (b.lastConfirm && !a.lastConfirm) return 1;

			if (a.lastConfirm > b.lastConfirm) return -1;
			if (b.lastConfirm > a.lastConfirm) return 1;
			return 0;
		});
	} else if (sort === "appVersion") {
		sortedNannies = displayNannies.sort((a, b) => {
			if (a.appVersion && !b.appVersion) return -1;
			if (b.appVersion && !a.appVersion) return 1;

			if (a.appVersion > b.appVersion) return -1;
			if (b.appVersion > a.appVersion) return 1;
			return 0;
		});
	} else {
		sortedNannies = displayNannies;
	}

	if (!asc) sortedNannies.reverse();

	return (
		<>
			<Input
				className={styles.searchInput}
				placeholder="Zoek nannies..."
				onChange={handleChange}
				value={searchText}
				fluid
			/>
			<div className={styles.container}>
				<Table celled collapsing={false}>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell onClick={onSortClick("nannyId")}>
								NannyId
							</Table.HeaderCell>
							<Table.HeaderCell onClick={onSortClick("name")}>Naam</Table.HeaderCell>
							<Table.HeaderCell onClick={onSortClick("login")}>
								Laatste login
							</Table.HeaderCell>
							<Table.HeaderCell onClick={onSortClick("confirm")}>
								Laatste keer dagen bevestigd
							</Table.HeaderCell>
							<Table.HeaderCell onClick={onSortClick("appVersion")}>
								Versie app
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{sortedNannies.map((nanny) => (
							<Table.Row>
								<Table.Cell>{nanny.id}</Table.Cell>
								<Table.Cell>{nanny.name}</Table.Cell>
								<Table.Cell>
									{nanny.lastLogin
										? moment(nanny.lastLogin).format("DD-MM-YYYY HH:mm")
										: "Nooit"}
								</Table.Cell>
								<Table.Cell>
									{nanny.lastConfirm
										? moment(nanny.lastConfirm).format("DD-MM-YYYY HH:mm")
										: "Nooit"}
								</Table.Cell>
								<Table.Cell>{nanny.appVersion || ""}</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
			</div>
		</>
	);
};

export default NanniesTab;
