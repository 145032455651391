import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptions } from "../redux/modules/Subscriptions";

export const useSubscriptions = () => {
    const dispatch = useDispatch();

    const subscriptions = useSelector((state) => state.subscriptions);

    React.useEffect(() => {
        if (subscriptions.subscriptions) return;
        if (subscriptions.loading) return;
        
        dispatch(getSubscriptions());
    }, [dispatch]);

    return subscriptions.subscriptions;
}