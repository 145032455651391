import React from "react";
import moment from "moment";
import { Dropdown, FormDropdown } from "semantic-ui-react";

import FormLabel from "../FormLabel";
import { capitalizeFirstLetter } from "../../constants";

const STARTDATE = moment("2019-10-01");

const getMonthOptions = (monthsAhead) => {
	const cur = moment().add(monthsAhead, "M").date(1);

	let options = [];
	for (; cur.isAfter(STARTDATE); cur.subtract(1, "month")) {
		options.push({
			key: cur.format("YYYY_MM"),
			text: capitalizeFirstLetter(cur.format("MMMM Y")),
			value: cur.format("YYYY_MM")
		});
	}
	return options;
};

const MonthDropdown = ({ fluid = true, month, setMonth, label, disabled, monthsAhead = 3 }) => (
	<>
		{label && <FormLabel label={label} />}
		<FormDropdown
			className="field"
			fluid={fluid}
			selection
			value={month}
			onChange={(e, { value }) => setMonth(value)}
			options={getMonthOptions(monthsAhead)}
			disabled={disabled}
		/>
	</>
);

export default MonthDropdown;
