import { useMemo } from "react";
import { useDienstenListener } from "./useDiensten";
import { useSubscriptionDiensten } from "./useSubscriptionDiensten";
import { DienstStatus, isCancelled } from "../constants";

export const useActualDiensten = (
	familyId,
	fromMonthStr,
	toMonthStr,
	includeReplaced = false,
	includeDiensten = true,
	includeSubscriptionDiensten = true,
	onlyLocalChanges = false,
	includeCancelled = true
) => {
	const { loading, diensten } = useDienstenListener(
		familyId,
		fromMonthStr,
		toMonthStr,
		onlyLocalChanges
	);
	const { loading: subscriptionDienstenLoading, diensten: subscriptionDiensten } =
		useSubscriptionDiensten(familyId, fromMonthStr, toMonthStr);

	const actualDiensten = useMemo(() => {
		let actualDiensten = [];

		let replacedDiensten = {};
		if (diensten) {
			if (includeDiensten) {
				let dienstenNonCancelled = includeCancelled
					? diensten
					: diensten.filter((d) => !isCancelled(d.status));
				actualDiensten = actualDiensten.concat(dienstenNonCancelled);
			}

			for (let i = 0; i < diensten.length; i++) {
				const dienst = diensten[i];
				if (dienst.replacementDienstId) {
					replacedDiensten[dienst.replacementDienstId] = dienst;
				}
			}
		}

		if (includeSubscriptionDiensten && subscriptionDiensten) {
			// check replacements
			let subscriptionDienstenWithReplaced = subscriptionDiensten;
			if (includeReplaced) {
				subscriptionDienstenWithReplaced = subscriptionDiensten.map((dienst) => {
					if (replacedDiensten[dienst.id]) {
						return { ...dienst, status: DienstStatus.Replaced };
					}
					return dienst;
				});
			} else {
				subscriptionDienstenWithReplaced = subscriptionDiensten.filter(
					(d) => !replacedDiensten[d.id]
				);
			}

			let subscriptionDienstenNonCancelled = includeCancelled
				? subscriptionDienstenWithReplaced
				: subscriptionDienstenWithReplaced.filter((d) => !isCancelled(d.status));

			actualDiensten = actualDiensten.concat(subscriptionDienstenNonCancelled);
		}

		actualDiensten.sort((a, b) => a.date.localeCompare(b.date));

		return actualDiensten;
	}, [
		includeDiensten,
		diensten,
		includeSubscriptionDiensten,
		subscriptionDiensten,
		includeReplaced
	]);

	return { loading: loading || subscriptionDienstenLoading, actualDiensten };
};
