import fileDownload from "js-file-download";
import moment from "moment";

// date	nanny	gezin	customer_id	lastminute	nextinvoice	inschrijfkosten	comments
export const exportRecords = (
	recordsObj,
	nanniesByID,
	familiesByID,
	onlyConfirmed,
	month,
	lastExport
) => {
	const { records, confirmed } = recordsObj;

	const headerArr = [
		"date",
		"dag van de week",
		"nanny_id",
		"nanny",
		"customer_id",
		"gezin",
		"lastminute",
		"nextinvoice",
		"inschrijfkosten",
		"comments"
	];
	if (!onlyConfirmed) headerArr.push("bevestigd");
	headerArr.push("ingevuld");

	const data = [headerArr.join(";")];

	const sortedRecords = [...records].sort((a, b) => {
		if (a.familyId < b.familyId) return -1;
		if (b.familyId < a.familyId) return 1;
		return 0;
	});

	for (let i = 0; i < sortedRecords.length; i++) {
		const curValue = sortedRecords[i];
		if (!curValue.dates) continue;

		const isConfirmed = curValue.nannyId in confirmed;
		if (onlyConfirmed && !isConfirmed) continue; // not confirmed, skip

		const nannyName = nanniesByID[curValue.nannyId]
			? nanniesByID[curValue.nannyId].name
			: "ONBEKEND";
		const familyName = familiesByID[curValue.familyId]
			? familiesByID[curValue.familyId].name
			: "ONBEKEND";

		for (const [key, value] of Object.entries(curValue.dates)) {
			if (lastExport && value.toDate) {
				if (value.toDate() < lastExport.toDate()) continue; // Filled before last export
			}

			let row = [];
			row.push(key);
			row.push(moment(key, "YYYY-MM-DD").format("dddd"));
			row.push(curValue.nannyId);
			row.push(nannyName);
			row.push(curValue.familyId);
			row.push(familyName);
			row.push(""); //lastminute
			row.push(""); //nextinvoice
			row.push(""); //inschrijfkosten
			row.push(curValue.comment || ""); //comments

			if (!onlyConfirmed) row.push(isConfirmed ? "ja" : "nee");

			if (value.toDate) {
				row.push(moment(value.toDate()).format("DD-MM-YYYY HH:mm"));
			} else {
				row.push("Onbekend (oude app versie)");
			}

			// Wrap in quotes
			row = row.map((val) => '"' + val + '"');

			data.push(row.join(";"));
		}
	}

	let fn;
	if (onlyConfirmed) {
		fn = "bevestigde_dagen--" + month + ".csv";
	} else if (lastExport) {
		fn = "dagen_sinds_laatste_export--" + month + ".csv";
	} else {
		fn = "alle_ingevulde_dagen--" + month + ".csv";
	}

	fileDownload(data.join("\n"), fn);
};
