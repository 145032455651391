import { createSlice } from "@reduxjs/toolkit";

import firebase from "firebase/app";
import "firebase/firestore";

const nanniesSlice = createSlice({
	name: "nannies",
	initialState: {
		loading: false,
		nannies: null
	},
	reducers: {
		getNanniesLoading: (state) => {
			state.loading = true;
		},
		getNanniesSuccess: (state, action) => {
			const { data } = action.payload;
			state.nannies = data;
			state.loading = false;
		},
		setKnownNannies: (state, action) => {
			const { data } = action.payload;
			state.knownNanniesPerFamily = data;
		}
	}
});

export default nanniesSlice.reducer;

export const { getNanniesLoading, getNanniesSuccess, setKnownNannies } = nanniesSlice.actions;
