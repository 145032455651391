import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Segment, Icon, Button, Form, Message } from "semantic-ui-react";
import MonthDropdown from "../MonthDropdown";
import FormLabel from "../FormLabel";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import styles from "./PushTab.module.css";

const PushTab = () => {
	const [state, setState] = React.useState("");
	const [month, setMonth] = React.useState(moment().date(1).format("YYYY_MM"));
	const [sendTo, setSendTo] = React.useState("all");
	const [title, setTitle] = React.useState("");
	const [content, setContent] = React.useState("");

	const handleChangeSendTo = (e, { value }) => setSendTo(value);

	const clearState = (e) => {
		setMonth(moment().date(1).format("YYYY_MM"));
		setSendTo("all");
		setTitle("");
		setContent("");
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!title || title === "") return;
		if (!content || content === "") return;
		if (state === "loading") return;

		setState("loading");

		const data = {
			sendTo,
			title,
			content
		};

		if (sendTo === "unconfirmed") {
			data.month = month;
		}

		firebase
			.firestore()
			.collection("push")
			.add(data)
			.then((docRef) => {
				setState("success");
				clearState();
			})
			.catch((error) => {
				setState("error");
				console.log(error);
			});
	};

	let message = null;
	if (state === "error") {
		message = (
			<Message
				error
				header="Onbekende fout bij het versturen van de berichten!"
				content="Probeer het later nog een keer..."
			/>
		);
	} else if (state === "success") {
		message = (
			<Message
				success
				header="Push-berichten verzenden gelukt!"
				content="Het kan even duren voor de berichten aankomen bij de nannies!"
			/>
		);
	}

	return (
		<div className={styles.container}>
			<div className={styles.item}>
				<Segment.Group className={styles.segmentGroup}>
					<Segment className={styles.header}>Push-berichten sturen</Segment>
					<Segment className={styles.content} loading={state === "loading"}>
						<Form onSubmit={handleSubmit}>
							<FormLabel label="Verstuur aan" />
							<Form.Group inline>
								<Form.Radio
									label="Alle nannies"
									value="all"
									checked={sendTo === "all"}
									onChange={handleChangeSendTo}
								/>
								<Form.Radio
									label="Nannies die nog niet bevestigd hebben"
									value="unconfirmed"
									checked={sendTo === "unconfirmed"}
									onChange={handleChangeSendTo}
								/>
							</Form.Group>
							{sendTo === "unconfirmed" && (
								<MonthDropdown
									label="Nannies die in deze maand nog niet bevestigd hebben"
									month={month}
									setMonth={setMonth}
								/>
							)}
							<Form.Input
								fluid
								label="Titel"
								placeholder="Titel van het push-bericht"
								value={title}
								onChange={(e, { value }) => setTitle(value)}
							/>
							<Form.TextArea
								label="Bericht"
								placeholder="Inhoud van het push-bericht"
								value={content}
								onChange={(e, { value }) => setContent(value)}
							/>
							<div className={styles.exportButtonContainer}>
								<Button
									type="submit"
									icon
									labelPosition="right"
									className={styles.exportButton}
								>
									Verstuur pushberichten
									<Icon name="send" />
								</Button>
							</div>
						</Form>
						{message}
					</Segment>
				</Segment.Group>
			</div>
		</div>
	);
};

export default PushTab;
