import React, { useEffect, useMemo } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getNanniesLoading, getNanniesSuccess } from "../redux/modules/Nannies";

export const useNanniesListener = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getNanniesLoading());

		const collectionRef = firebase.firestore().collection("nannies");
		return collectionRef.onSnapshot((querySnapshot) => {
			let data = [];
			querySnapshot.forEach((doc) => {
				const { lastLogin, lastConfirm, lastDienstenRead, ...rest } = doc.data();

				let userObj = {
					id: doc.id,
					lastLogin: lastLogin && lastLogin.toDate(),
					lastConfirm: lastConfirm && lastConfirm.toDate(),
					lastDienstenRead: lastDienstenRead && lastDienstenRead.toDate(),
					...rest
				};

				data.push(userObj);
			});

			dispatch(getNanniesSuccess({ data }));
		});
	}, []);
};

export const useNannies = () => {
	const nannies = useSelector((state) => state.nannies.nannies);
	return nannies;
};

export const useNanniesById = () => {
	const nannies = useNannies();
	return useMemo(
		() => nannies?.reduce((obj, item) => ((obj[item.id] = item), obj), {}),
		[nannies]
	);
};
