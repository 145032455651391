import firebase from "firebase/app";
import "firebase/firestore";

import { readFile } from "../../reader";
import { ISTEST } from "../..";

const COLUMNIDX = {
	KLANTNUMMER: 0,
	GEZIN: 1,
	NANNYID: 2,
	NANNY: 3,
	STARTDATE: 4,
	ENDDATE: 5,
	TARIFF: 6,
	MONDAYFROM: 7,
	MONDAYTO: 8,
	TUESDAYFROM: 9,
	TUESDAYTO: 10,
	WEDNESDAYFROM: 11,
	WEDNESDAYTO: 12,
	THURSDAYFROM: 13,
	THURSDAYTO: 14,
	FRIDAYFROM: 15,
	FRIDAYTO: 16,
	SATURDAYFROM: 17,
	SATURDAYTO: 18,
	SUNDAYFROM: 19,
	SUNDAYTO: 20,
	LAST: 21
};

const columns = [
	{ index: COLUMNIDX.KLANTNUMMER, required: true, upper: true },
	{ index: COLUMNIDX.GEZIN, required: true, upper: false },
	{ index: COLUMNIDX.NANNYID, required: false, upper: true },
	{ index: COLUMNIDX.NANNY, required: false, upper: false },
	{ index: COLUMNIDX.STARTDATE, required: false, upper: false },
	{ index: COLUMNIDX.ENDDATE, required: false, upper: false },
	{ index: COLUMNIDX.TARIFF, required: true, upper: false },
	{ index: COLUMNIDX.MONDAYFROM, required: false, upper: false },
	{ index: COLUMNIDX.MONDAYTO, required: false, upper: false },
	{ index: COLUMNIDX.TUESDAYFROM, required: false, upper: false },
	{ index: COLUMNIDX.TUESDAYTO, required: false, upper: false },
	{ index: COLUMNIDX.WEDNESDAYFROM, required: false, upper: false },
	{ index: COLUMNIDX.WEDNESDAYTO, required: false, upper: false },
	{ index: COLUMNIDX.THURSDAYFROM, required: false, upper: false },
	{ index: COLUMNIDX.THURSDAYTO, required: false, upper: false },
	{ index: COLUMNIDX.FRIDAYFROM, required: false, upper: false },
	{ index: COLUMNIDX.FRIDAYTO, required: false, upper: false },
	{ index: COLUMNIDX.SATURDAYFROM, required: false, upper: false },
	{ index: COLUMNIDX.SATURDAYTO, required: false, upper: false },
	{ index: COLUMNIDX.SUNDAYFROM, required: false, upper: false },
	{ index: COLUMNIDX.SUNDAYTO, required: false, upper: false }
];

export const importSubscriptionsFile = (
	file,
	oldSubscriptions,
	oldNannySubscriptions,
	onlyFixedDays
) => {
	return new Promise((resolve, reject) => {
		var reader = new FileReader();
		reader.onload = function (progressEvent) {
			var oldSubscriptionsByID = { ...oldSubscriptions };
			if (onlyFixedDays) {
				console.log(
					"Only importing fixed days, only old subscriptions with fixed days will be deleted"
				);

				// Remove non-fixed subscriptions
				for (let key in oldSubscriptions) {
					if (!oldSubscriptions[key].subscriptions.some((s) => s.days.includes(true))) {
						delete oldSubscriptionsByID[key];
					}
				}
			}

			var oldNannySubscriptionsByID = { ...oldNannySubscriptions };

			const newNannySubscriptions = {};
			const newFamilies = {};
			const success = readFile(this.result, columns, reject, (lineArray) => {
				let familyId = lineArray[COLUMNIDX.KLANTNUMMER];
				if (familyId.substring(0, 2) !== "AN" && familyId.substring(0, 6) !== "TESTAN") {
					console.log("FamilyId incorrect: " + familyId);
					return;
				}

				familyId = familyId.replace(" ", "");
				familyId = familyId.replace("\t", "");

				// Verzamel vaste dagen
				let subscription = {
					days: Array(7).fill(false),
					times: Array(7).fill({ from: "", to: "" }),
					tariffId: Number(lineArray[COLUMNIDX.TARIFF]),
					startDate: lineArray[COLUMNIDX.STARTDATE],
					endDate: lineArray[COLUMNIDX.ENDDATE]
				};

				let nannyId = lineArray[COLUMNIDX.NANNYID];
				if (nannyId?.substring(0, 3) === "NAN") {
					subscription.nannyId = nannyId;
				}

				let hasFixedDay = false;
				for (let d = 0; d < 7; d++) {
					let fromColumn = COLUMNIDX.MONDAYFROM + d * 2;
					let toColumn = COLUMNIDX.MONDAYTO + d * 2;
					if (toColumn >= lineArray.length) continue;

					if (!lineArray[fromColumn]) continue;
					if (!lineArray[toColumn]) {
						console.log("From but no to time: " + familyId);
						continue;
					}

					subscription.days[d] = true;
					subscription.times[d] = {
						from: lineArray[fromColumn],
						to: lineArray[toColumn]
					};

					hasFixedDay = true;
				}

				if (onlyFixedDays && !hasFixedDay) {
					console.log(
						"Importing only fixed days, and no fixed days for family: " + familyId
					);
					return;
				}

				if (!(familyId in newFamilies)) {
					newFamilies[familyId] = {
						subscriptions: []
					};
				}

				newFamilies[familyId].name = lineArray[COLUMNIDX.GEZIN];
				newFamilies[familyId].subscriptions.push(subscription);

				// Add to nanny perspective subscriptions
				if (nannyId && hasFixedDay) {
					if (!(nannyId in newNannySubscriptions)) {
						newNannySubscriptions[nannyId] = {
							subscriptionsForNanny: []
						};
					}

					const { tariffId, ...subscriptionForNanny } = subscription;
					subscriptionForNanny.familyId = familyId;
					newNannySubscriptions[nannyId].subscriptionsForNanny.push(subscriptionForNanny);
				}
			});

			if (!success) {
				reject("!success in importFamiliesFile");
				return;
			}

			var newFamKeys = Object.keys(newFamilies);
			if (!ISTEST && newFamKeys.length <= 10) {
				reject(
					"Minder dan 10 Families gevonden in bestand, voor de zekerheid wordt er niet geupload"
				);
				return;
			}

			const promises = [];

			// Upload to subscriptions
			while (newFamKeys.length) {
				const chunk = newFamKeys.splice(0, 500);
				const batch = firebase.firestore().batch();
				for (let key of chunk) {
					const ref = firebase.firestore().collection("subscriptions").doc(key);
					batch.set(ref, newFamilies[key]);

					if (key in oldSubscriptionsByID) {
						delete oldSubscriptionsByID[key];
					}
				}

				promises.push(batch.commit());
			}

			// Delete old subscriptions, not in new file anymore
			var oldSubscriptionsKeys = Object.keys(oldSubscriptionsByID);
			while (oldSubscriptionsKeys.length) {
				const chunk = oldSubscriptionsKeys.splice(0, 500);
				const batch = firebase.firestore().batch();

				for (let key of chunk) {
					if (key.includes("TEST")) continue; // keep testfamilies
					const ref = firebase.firestore().collection("subscriptions").doc(key);
					batch.delete(ref);
				}

				promises.push(batch.commit());
			}

			// Upload to nanny subscriptions
			var newNannyKeys = Object.keys(newNannySubscriptions);
			while (newNannyKeys.length) {
				const chunk = newNannyKeys.splice(0, 500);
				const batch = firebase.firestore().batch();
				for (let key of chunk) {
					if (!key) continue;

					const ref = firebase.firestore().collection("nannySubscriptions").doc(key);
					batch.set(ref, newNannySubscriptions[key]);

					if (key in oldNannySubscriptionsByID) {
						delete oldNannySubscriptionsByID[key];
					}
				}

				promises.push(batch.commit());
			}

			// Delete old nanny subscriptions, not in new file anymore
			var oldNannySubscriptionsKeys = Object.keys(oldNannySubscriptionsByID);
			while (oldNannySubscriptionsKeys.length) {
				const chunk = oldNannySubscriptionsKeys.splice(0, 500);
				const batch = firebase.firestore().batch();

				for (let key of chunk) {
					const ref = firebase.firestore().collection("nannySubscriptions").doc(key);
					batch.delete(ref);
				}

				promises.push(batch.commit());
			}

			Promise.all(promises).then(resolve).catch(reject);
		};

		reader.readAsText(file);
	});
};
