import React from 'react'
import { Loader } from 'semantic-ui-react'

import styles from "./LoadingScreen.module.css"

const Loading = () => (
    <div className={styles.container}>
        <Loader active inverted />
    </div>
)

export default Loading 
