import { createSlice } from "@reduxjs/toolkit";

import firebase from "firebase/app";
import "firebase/firestore";

const subscriptionsSlice = createSlice({
	name: "subscriptions",
	initialState: {
		loading: false,
		subscriptions: null
	},
	reducers: {
		getSubscriptionsLoading: (state) => {
			state.loading = true;
		},
		getSubscriptionsSuccess: (state, action) => {
			const { data } = action.payload;
			state.subscriptions = data;
			state.loading = false;
		}
	}
});

export default subscriptionsSlice.reducer;

const { getSubscriptionsLoading, getSubscriptionsSuccess } = subscriptionsSlice.actions;

export const getSubscriptions = () => async (dispatch) => {
	dispatch(getSubscriptionsLoading());

	console.log("Getting subscriptions...");

	firebase
		.firestore()
		.collection("subscriptions")
		.onSnapshot((querySnapshot) => {
			console.log("subscriptions snapshot came in");

			let data = {};
			querySnapshot.forEach((doc) => {
				data[doc.id] = doc.data();
			});

			dispatch(getSubscriptionsSuccess({ data }));
		});
};
