import React, { useEffect, useMemo } from "react";
import { useFamiliesById } from "../../hooks/useFamilies";
import { Form } from "semantic-ui-react";

export const FamilyDropdown = ({ value, onChange, label, ...rest }) => {
	const familiesByID = useFamiliesById();

	const familyOptions = useMemo(() => {
		if (!familiesByID) return [];
		return Object.keys(familiesByID).map((familyId) => ({
			text: `${familiesByID[familyId].name} (${familyId})`,
			value: familyId,
			key: familyId
		}));
	}, [familiesByID]);

	useEffect(() => {
		if (!familyOptions) return;
		if (value) return;
		onChange(null, { value: familyOptions[0].value });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [familyOptions]);

	return (
		<Form.Dropdown
			label={label || "Gezin"}
			selection
			search
			options={familyOptions}
			value={value}
			onChange={onChange}
			{...rest}
		/>
	);
};
