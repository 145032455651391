import React from "react"
import { useTariffs } from "../../hooks/useTariffs";

import { Icon, Input, Segment, Menu, Button, Table } from "semantic-ui-react"
import TariffModal from "./TariffModal";
import TariffTable from "./TariffTable";

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import styles from "./TariffTab.module.css";

const TariffTab = ({ }) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalTariffId, setModalTariffId] = React.useState(-1);

    const tariffs = useTariffs();

    const onAddEditTariff = (idx, data) => {
        firebase.firestore().collection("tariffs").doc(String(idx)).set(data).then((docRef) => {
            console.log("Success");
        }).catch((error) => {
            console.log(error);
        });
    }

    const newTariffId = tariffs ? tariffs.length : 0;
    const onAddTariffClick = () => {
        setModalOpen(true);
        setModalTariffId(newTariffId);
    }

    const onRowClick = (idx) => {
        setModalOpen(true);
        setModalTariffId(idx);
    }

    let tariffData;
    if (tariffs && modalTariffId < tariffs.length) {
        tariffData = tariffs[modalTariffId];
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.item}>
                    <Button icon labelPosition='right' onClick={onAddTariffClick}>
                        Nieuw tarief
                        <Icon name="add" />
                    </Button>
                    <TariffModal
                        open={modalOpen}
                        setOpen={setModalOpen}
                        tariffCode={modalTariffId}
                        tariff={tariffData}
                        onConfirm={(data) => onAddEditTariff(modalTariffId, data)}
                    />
                    <TariffTable
                        tariffs={tariffs}
                        onRowClick={onRowClick}
                    />
                </div>
            </div>
        </>
    )
}

export default TariffTab;