import React from 'react';
import { connect } from "react-redux";

import { logout } from "../../redux/modules/Auth";

import { Menu, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import styles from "./Navbar.module.css";

const Navbar = ({authUser, logout}) => (
    <Menu className={styles.navbar} fixed="top" inverted>
        <Menu.Item header>
            <Link to="/home">Amsternannies</Link>
        </Menu.Item>
        <Menu.Menu position='right'>
            <Menu.Item><Icon name="user" />{authUser.name}</Menu.Item>
            <Menu.Item header name='logout' onClick={logout} />
        </Menu.Menu>
    </Menu>
)

const mapStateToProps = (state) => ({
    authUser: state.auth.authUser
})

const mapDispatchToProps = {
    logout
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Navbar);