import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Form } from "semantic-ui-react";
import LoadingScreen from "../LoadingScreen";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import { setAuthUser } from "../../redux/modules/Auth";

import styles from "./Login.module.css";

const canLogin = (email, password) => {
	if (!email) return false;
	if (!password) return false;
	if (email === "") return false;
	if (!email.includes("@")) return false;
	if (!email.includes(".")) return false;

	if (password === "") return false;

	return true;
};

const Login = ({ authUser, setAuthUser }) => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		return firebase.auth().onAuthStateChanged((authUser) => {
			console.log(authUser);
			if (authUser) {
				authUser
					.getIdTokenResult()
					.then((idTokenResult) => {
						// Confirm the user is an Admin.
						let userData = {
							authUser: authUser ? authUser.uid : null
						};

						if (!!idTokenResult.claims.superAdmin) {
							// Show admin UI.
							console.log("logged in as super admin");
							userData.superAdmin = true;
						} else if (!!idTokenResult.claims.admin) {
							// Show admin UI.
							console.log("logged in as admin");
						} else {
							// Show regular user UI.
							authUser = null;
							firebase.auth().signOut();
							console.log("logged in but no admin");
						}

						setLoading(false);
						setAuthUser(userData);
					})
					.catch((error) => {
						setLoading(false);
						console.log(error);
					});
			} else {
				console.log("Not signed in");

				setLoading(false);
				setAuthUser({
					authUser: authUser ? authUser.uid : null
				});
			}
		});
	}, [setAuthUser]);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!canLogin(username, password)) return;
		if (authUser) return; //Already signed in
		console.log("Trying to sign in");
		setLoading(true);
		firebase
			.auth()
			.signInWithEmailAndPassword(username, password)
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};

	// if (authUser) return <Redirect to="/home" />
	if (loading) return <LoadingScreen />;

	return (
		<div className={styles.container}>
			<div className={styles.formContainer}>
				<h1 className={styles.header}>Amsternannies</h1>
				<Form onSubmit={handleSubmit}>
					<Form.Input
						fluid
						name="username"
						placeholder="Emailadres"
						adrestype="text"
						value={username}
						onChange={(e, { value }) => setUsername(value)}
						iconPosition="left"
						icon="at"
					/>
					<Form.Input
						fluid
						name="password"
						placeholder="Wachtwoord"
						type="password"
						value={password}
						onChange={(e, { value }) => setPassword(value)}
						iconPosition="left"
						icon="lock"
					/>
					<Form.Button fluid>Login</Form.Button>
				</Form>
			</div>
		</div>
	);
};

const mapDispatchToProps = {
	setAuthUser
};

const mapStateToProps = (state) => ({
	authUser: state.auth.authUser
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
