import React from "react";
import { connect } from "react-redux";

import Navbar from "../Navbar";
import { Tab } from "semantic-ui-react";

import FamiliesTab from "../FamiliesTab";
import NanniesTab from "../NanniesTab";
import ExportDaysTab from "../ExportDaysTab";
import PushTab from "../PushTab";
import ImportTab from "../ImportTab";
import TariffTab from "../TariffTab";
import InvoiceTab from "../InvoiceTab";
import OverviewTab from "../OverviewTab";
import { DienstenTab } from "../DienstenTab";
import { PDFFilesTab } from "../PDFFilesTab";
import { SubscriptionDienstenTab } from "../SubscriptionDienstenTab";

import styles from "./Home.module.css";
import { useNanniesListener } from "../../hooks/useNannies";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { useNannySubscriptions } from "../../hooks/useNannySubscriptions";
import { useKnownNanniesListener } from "../../hooks/useKnownNannies";

const panes = [
	{ menuItem: "Overzicht", render: () => <OverviewTab /> },
	{ menuItem: "Planning", render: () => <DienstenTab /> },
	{ menuItem: "Vaste dagen", render: () => <SubscriptionDienstenTab /> },
	{ menuItem: "Exporteer dagen", render: () => <ExportDaysTab /> },
	{ menuItem: "Nannies", render: () => <NanniesTab /> },
	{ menuItem: "Families", render: () => <FamiliesTab /> },
	{ menuItem: "Push-berichten", render: () => <PushTab /> },
	{ menuItem: "Importeer", render: () => <ImportTab /> },
	{ menuItem: "PDF's voor in de app", render: () => <PDFFilesTab /> }
];

const adminPanes = [
	{ menuItem: "Tarieven", render: () => <TariffTab /> },
	{ menuItem: "Facturen", render: () => <InvoiceTab /> }
];

const Home = ({ superAdmin }) => {
	// Make sure we are listening to all relevant data once
	useNanniesListener();
	useKnownNanniesListener();

	let showPanes = panes;
	if (superAdmin) {
		showPanes = [...panes, ...adminPanes];
	}

	return (
		<div className={styles.container}>
			<Navbar />
			<div className={styles.content}>
				<Tab
					className={styles.tab}
					menu={{ secondary: true, pointing: true }}
					panes={showPanes}
					defaultActiveIndex={0}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	superAdmin: state.auth.superAdmin
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
