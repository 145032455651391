import firebase from "firebase/app";
import "firebase/firestore";

import { readFile } from "../../reader";

const COLUMNIDX = {
	KLANTNUMMER: 0,
	GEZIN: 1,
	NANNYID: 2,
	NANNYNAME: 3,
	LAST: 14
};

const columns = [
	{ index: COLUMNIDX.KLANTNUMMER, required: true, upper: true },
	{ index: COLUMNIDX.GEZIN, required: true, upper: false },
	{ index: COLUMNIDX.NANNYID, required: false, upper: true },
	{ index: COLUMNIDX.NANNYNAME, required: false, upper: false }
];

const importFamiliesFile = (file, families, nannies) => {
	return new Promise((resolve, reject) => {
		var reader = new FileReader();
		reader.onload = function (progressEvent) {
			//convert
			var oldFamiliesByID = families.reduce((obj, item) => ((obj[item.id] = item), obj), {});
			var nanniesByID = nannies.reduce((obj, item) => ((obj[item.id] = item), obj), {});

			const newNannies = {};
			const newFamilies = {};

			const success = readFile(this.result, columns, reject, (lineArray) => {
				let familyId = lineArray[COLUMNIDX.KLANTNUMMER];
				if (familyId.substring(0, 2) !== "AN" && familyId.substring(0, 6) !== "TESTAN") {
					console.log("FamilyId incorrect: " + familyId);
					return;
				}

				if (!(familyId in newFamilies)) {
					newFamilies[familyId] = {};
				}

				newFamilies[familyId].name = lineArray[COLUMNIDX.GEZIN];

				let nannyId = lineArray[COLUMNIDX.NANNYID];
				if (nannyId?.substring(0, 3) === "NAN") {
					if (!(nannyId in newNannies)) {
						newNannies[nannyId] = {
							families: []
						};
					}

					newNannies[nannyId].families.push(familyId);
				} else if (nannyId) {
					console.log("nannyId incorrect: " + nannyId);
				}
			});

			if (!success) {
				reject("!success in importFamiliesFile");
				return;
			}

			var newFamKeys = Object.keys(newFamilies);
			if (newFamKeys.length <= 10) {
				reject(
					"Minder dan 10 Families gevonden in bestand, voor de zekerheid wordt er niet geupload"
				);
				return;
			}

			const promises = [];
			while (newFamKeys.length) {
				const chunk = newFamKeys.splice(0, 500);
				const batch = firebase.firestore().batch();
				for (let key of chunk) {
					const ref = firebase.firestore().collection("families").doc(key);
					batch.set(ref, newFamilies[key], { merge: true });

					if (key in oldFamiliesByID) {
						delete oldFamiliesByID[key];
					}
				}

				promises.push(batch.commit());
			}

			var newNanniesKeys = Object.keys(newNannies);
			while (newNanniesKeys.length) {
				const chunk = newNanniesKeys.splice(0, 500);
				const batch = firebase.firestore().batch();

				for (let key of chunk) {
					if (!nanniesByID[key]) {
						console.log(key + " niet in Nannies bestand");
						continue;
					}

					const ref = firebase.firestore().collection("nannies").doc(key);
					batch.set(ref, newNannies[key], { merge: true });
				}

				promises.push(batch.commit());
			}

			var oldFamiliesKeys = Object.keys(oldFamiliesByID);
			while (oldFamiliesKeys.length) {
				const chunk = oldFamiliesKeys.splice(0, 500);
				const batch = firebase.firestore().batch();

				for (let key of chunk) {
					if (key.includes("TEST")) continue; // keep testfamilies
					const ref = firebase.firestore().collection("families").doc(key);
					batch.delete(ref);
				}

				promises.push(batch.commit());
			}

			Promise.all(promises).then(resolve).catch(reject);
		};

		reader.readAsText(file);
	});
};

export default importFamiliesFile;
