import React from 'react'
import { Button, Modal, Table } from 'semantic-ui-react'

const NewInvoiceModal = ({ open, onClose, familyId, familyName, invoice }) => {
    return (
        <Modal
            onClose={onClose}
            open={open}
            size="large"
        >
            <Modal.Header>Factuur voor {familyId} - {familyName}</Modal.Header>
            <Modal.Content>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Datum</Table.HeaderCell>
                            <Table.HeaderCell>Dag van de week</Table.HeaderCell>
                            <Table.HeaderCell>Omschrijving</Table.HeaderCell>
                            <Table.HeaderCell>Bedrag</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {invoice && invoice.lines.map((line, idx) => (
                            <Table.Row key={idx}>
                                <Table.Cell>{line.date}</Table.Cell>
                                <Table.Cell>{line.dayOfWeek}</Table.Cell>
                                <Table.Cell>{line.description}</Table.Cell>
                                <Table.Cell>€ {line.amount ? line.amount.toFixed(2) : "0.00"}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>

                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan="3"><b>Totaal:</b></Table.HeaderCell>
                            <Table.HeaderCell><b>€ {invoice ? invoice.totalAmount.toFixed(2) : "0.00"}</b></Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>
                    Ok
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default NewInvoiceModal